import Button from '@Components/Button'
import Dropzone from '@Components/DropzoneBox/v3';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MuiThemeProvider, TextField, createMuiTheme } from '@material-ui/core'
import { Add, AddOutlined, Close } from '@material-ui/icons'
import React, { useState } from 'react'

export default function UploadARModelDialog({ createARModel }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [modelName, setModelName] = useState('');
  const [files, setFiles] = useState([]);

  const uploadFunction = () => {
    if (!modelName) return;
    if (!files.length) return;
    createARModel({
      name: modelName, files
    });
    setModelName('');
    setFiles([]);
    setOpenDialog(false);
  }
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button className="color-gradient-inline" style={{ borderRadius: 18 }} onClick={() => setOpenDialog(true)}>
          <AddOutlined style={{ color: 'white' }} />
          <p className="text-white">Upload AR Model</p>
        </Button>
      </div>
      <MuiThemeProvider theme={getMuiTheme()}>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
          <div className="d-flex justify-content-between align-items-center">
            <DialogTitle>
              <p style={{ color: 'var(--dark-blue-color)', fontWeight: 600, fontSize: 20 }}>Upload AR Model</p>
            </DialogTitle>
            <IconButton style={{ backgroundColor: 'transparent', padding: 0 }}>
              <Close style={{ color: 'grey', padding: 20 }} onClick={() => setOpenDialog(false)} />
            </IconButton>
          </div>
          <DialogContent style={{ textAlign: 'center' }}>
            {[
              {
                inputLabel: 'Model Name', value: modelName, placeholder: 'Enter model name here', setValue: setModelName,
              },
            ].map(d => (
              <div style={{ width: '100%', marginBottom: 20 }}>
                <p style={{ display: 'flex', flex: 'start', marginBottom: 5, color: 'var(--dark-blue-color)' }}>
                  {d.inputLabel}
                </p>
                <TextField
                  {...d}
                  placeholder={d.placeholder}
                  variant="outlined"
                  fullWidth
                  value={d.value}
                  onChange={(e) => d.setValue(e.target.value)}
                />
              </div>
            ))}
            <Dropzone
              files={files}
              setFiles={setFiles}
              type="compress"
              height={100}
              maxFiles={1}
              customStyle={{ backgroundColor: 'white' }}
            // checkLocation
            // progress={progress}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'end', marginBlock: 10, marginRight: 20 }}>
            <Button
              variant="contained"
              style={{
                color: 'var(--primary-color)',
                width: '10rem',
                outline: '1px solid var(--primary-color)',
                backgroundColor: 'transparent',
                borderRadius: 30,
              }}
              onClick={() => setOpenDialog(false)}
            >
              <p className="ml-2">Cancel</p>
            </Button>
            <Button
              variant="contained"
              style={{
                color: '#FFFFFF',
                borderRadius: 30,
                background: 'linear-gradient(var(--main-color), var(--primary-color))',
                width: '10rem',
              }}
              onClick={uploadFunction}
            >
              <p className="text-white ml-2">Upload</p>
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </>
  )
}

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});