/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable max-lines */
// import { todayDateTime } from '@Helpers';
import Api, { endpoints } from '@Helpers/api';
import { useEffect, useState } from 'react';
import { addDay, capitalize } from '@Helpers';
import _, { filter } from 'lodash';
import { useLocation } from 'react-router-dom';

export default function Hook() {
    const location = useLocation();
    const today = new Date();
    // const startDate = new Date(today.getFullYear(), today.getMonth(), 1); // first day of the month
    const startDate = new Date(2025, 0, 1); // Required since insufficient data
    const endDate = today;

    // global action data
    const [tab, setTab] = useState('patrol');
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [selectedDetection, setSelectedDetection] = useState(null);
    const [selectedAnnotation, setSelectedAnnotation] = useState();
    const [detectionTypeSelected, setDetectionTypeSelected] = useState(['all']);

    // Declaration
    const pavementId = [52, 53, 54, 76, 77, 78];
    const droneId = [64, 66, 65, 79, 80, 81];
    const trafficsafetyId = [55, 56, 57, 58, 59, 60, 61, 62, 63, 67, 68, 69, 70, 71, 72, 73, 74, 75];
    const isPavement = location.pathname.endsWith('pavement');
    const isTrafficSafety = location.pathname.endsWith('traffic-safety');
    const allDetectionTypes = isTrafficSafety ? trafficsafetyId : (isPavement && tab === 'drone' ? droneId : pavementId);

    // loading state for API call
    const [isLoading, setIsLoading] = useState(false); // Main loading state
    const [isLoadingStaticData, setIsLoadingStaticData] = useState(false);
    const [isLoadingGroupData, setIsLoadingGroupData] = useState(false);
    const [isLoadingScatterData, setIsLoadingScatterData] = useState(false);
    const [isLoadingStackedData, setIsLoadingStackedData] = useState(false);
    const [isLoadingTableData, setIsLoadingTableData] = useState(false);

    // static data
    const scatterGroups = [{ name: 'NB', description: 'NorthBound' }, { name: 'SB', description: 'SouthBound' }, { name: 'total', description: 'Total' }];
    const [tabList] = useState([{ selector: 'patrol', label: 'PATROL' }, { selector: 'drone', label: 'DRONE' }]);
    const [networks, setNetworks] = useState([]);
    const [sections, setSections] = useState([]);
    const [regions, setRegions] = useState([]);
    const [detectionTypes, setDetectionTypes] = useState([]);

    // chart action data
    // 1. summaryPavement
    const [summaryPavementNetworkSelected, setSummaryPavementNetworkSelected] = useState(null);
    const [summaryPavementDateSelected, setSummaryPavementDateSelected] = useState({ startDate, endDate });
    const [summaryPavementDateSelectedActive, setSummaryPavementDateSelectedActive] = useState(false);
    const [summaryPavementDataAllValue, setSummaryPavementDataAllValue] = useState([]);
    const [summaryPavementData, setSummaryPavementData] = useState([]);
    // 2. countPavement
    const [countPavementNetworkSelected, setCountPavementNetworkSelected] = useState(null);
    const [countPavementDateSelected, setCountPavementDateSelected] = useState({ startDate, endDate });
    const [countPavementDateSelectedActive, setCountPavementDateSelectedActive] = useState(false);
    // const [countPavementDataAllValue, setCountPavementDataAllValue] = useState([]);
    const [countPavementData, setCountPavementData] = useState([]);
    // 3. countNetworkWithDate
    const [countNetworkDateSelected, setCountNetworkDateSelected] = useState({ startDate, endDate });
    const [countNetworkDateSelectedActive, setCountNetworkDateSelectedActive] = useState(false);
    const [countNetworkDataAllValue, setCountNetworkDataAllValue] = useState([]);
    const [countNetworkData, setCountNetworkData] = useState([]);
    // const [countNetworkDataStackedAllValue, setCountNetworkDataStackedAllValue] = useState([]);
    const [countNetworkDataStacked, setCountNetworkDataStacked] = useState([]);
    // 4. countRegionWithDate
    const [countRegionDateSelected, setCountRegionDateSelected] = useState({ startDate, endDate });
    const [countRegionDateSelectedActive, setCountRegionDateSelectedActive] = useState(false);
    const [countRegionDataAllValue, setCountRegionDataAllValue] = useState([]);
    const [countRegionData, setCountRegionData] = useState([]);
    // const [countRegionDataStackedAllValue, setCountRegionDataStackedAllValue] = useState([]);
    const [countRegionDataStacked, setCountRegionDataStacked] = useState([]);
    // 5. countSectionWithDate
    const [countSectionDateSelected, setCountSectionDateSelected] = useState({ startDate, endDate });
    const [countSectionDateSelectedActive, setCountSectionDateSelectedActive] = useState(false);
    const [countSectionDataAllValue, setCountSectionDataAllValue] = useState([]);
    const [countSectionData, setCountSectionData] = useState([]);
    // 6. tableData
    const [tableData, setTableData] = useState([]);
    const [tableFilterData, setTableFilterData] = useState([]);

    const [getDataByClick, setGetDataByClick] = useState({});
    const [sectionScatter, setSectionScatter] = useState('E1');

    const [takeActionList, setTakeActionList] = useState([]);
    const [isLoadingTakeAction, setIsLoadingTakeAction] = useState(false);
    const [isCollapse, setIsCollapse] = useState({
        response_time: false,
        action: true,
    });
    // Troubleshoot
    // console.log('c1 summary pavement', summaryPavementData);
    // console.log('c2 scatter data', countPavementData);
    // console.log('c3 bar count by network', countNetworkData);
    // console.log('c4 stacked bar count by network ', countNetworkDataStacked);
    // console.log('c5 bar count by region ', countRegionData);
    // console.log('c6 stacked bar count by region ', countNetworkDataStacked);
    // console.log('c7 count by section ', countSectionData);

    const roads = ['NB', 'SB', 'EB', 'WB'];

    // API Caller
    const getStaticData = () => {
        setIsLoadingStaticData(true);
        Api({
            endpoint: endpoints.getStaticData(),
            onSuccess: ({ data }) => {
                console.log('staticdata', data);
                const nets = data.Network.map(m => ({ label: m.name, value: m.id }));
                setNetworks(data.Network.map(m => ({ label: m.label, value: m.id })));
                setSummaryPavementNetworkSelected(nets[0].value); // preselect network
                setCountPavementNetworkSelected(nets[0].value); // preselect network
                setRegions(data.Region.map(m => ({ label: m.name, value: m.id })));
                setSections(data.Section.map(m => ({ label: m.name, value: m.id })));
                const dts = data.DetectionType
                    .filter(m => m.AssetTypeId === 3)
                    .filter(m => isTrafficSafety ? trafficsafetyId.includes(m.id) : (isPavement && tab === 'drone' ? droneId.includes(m.id) : pavementId.includes(m.id)))
                    .map(m => ({ label: m.name, value: String(m.id), id: m.id }));
                setDetectionTypes([{ label: 'All', value: 'all', id: 'all' }, ...dts]);
                setIsLoadingStaticData(false);
            },
            onFail: (err) => toast('error', err),
        });
    };
    const getDataByGroup = (query, setData, setDataValue, staticGroup = null) => {
        setIsLoadingGroupData(true);
        Api({
            endpoint: endpoints.getAnalyticsRoad(),
            data: { ...query },
            onSuccess: (data) => {
                let mutationData = {};
                let mutationDataValue = {};
                if (staticGroup) {
                    mutationData = Object.assign(
                        {},
                        ...Object.keys(data.data).filter(e => e !== '0' && !isNaN(e) && e !== String(null)).map(d => ({
                            [staticGroup.find(sg => sg.value == d).label]: data.data[d].length,
                        })),
                    );
                    mutationDataValue = Object.assign(
                        {},
                        ...Object.keys(data.data).filter(e => e !== '0' && !isNaN(e) && e !== String(null)).map(d => ({
                            [staticGroup.find(sg => sg.value == d).label]: data.data[d],
                        })),
                    );
                } else {
                    mutationData = Object.assign({}, ...Object.keys(data.data).map(d => ({ [d]: data.data[d].length })));
                    mutationDataValue = Object.assign({}, ...Object.keys(data.data).map(d => ({ [d]: data.data[d] })))
                }
                // deep sort
                mutationData = _.fromPairs(_.sortBy(_.toPairs(
                    mutationData
                ), [0]))
                mutationDataValue = _.fromPairs(_.sortBy(_.toPairs(
                    mutationDataValue
                ), [0]));
                setData(pv => (_.isEmpty(mutationData) ? [] : [mutationData]));
                setDataValue(pv => (_.isEmpty(mutationDataValue) ? [] : [mutationDataValue]))
                setIsLoadingGroupData(false);
            },
            onFail: (err) => toast('error', err),
        });
    };
    const getDataScatter = (query, setData) => {
        setIsLoadingScatterData(true);

        // Prepare source data (filter by AssetTypeId and extract location, bound, km)
        const prepareSource = (data) => data.data.all
            .filter((x) => x['InspectionFile.Inspection.Asset.AssetTypeId'] === 3)
            .map((d) => {
              const loc = d['InspectionFile.Inspection.Asset.location'];
              return {
                ...d,
                location: loc,
                bound: roads.find((r) => loc?.includes(r)) || null,
                km: Number(loc?.split('/KM')[1]) || null,
              };
            });

        // Group an array of records by km (sorted, filtered)
        const groupByKM = (data) => Object.entries(_.groupBy(data, 'km'))
            .sort((a, b) => Number(a[0]) - Number(b[0]))
            .map(([k, arr]) => ({ x: Number(k), y: arr.length, d: arr }))
            .filter((item) => item.x !== null && !isNaN(item.x));

        // Group data by road bounds (NB, SB, WB, EB, plus Total)
        const groupByBound = (data) => {
          const bounds = ['NB', 'SB', 'WB', 'EB'];
          return bounds
            .map((b) => ({
              bound: b === 'NB' ? 'NorthBound' : b === 'SB' ? 'SouthBound' : b === 'WB' ? 'WestBound' : 'EastBound',
              data: groupByKM(data.filter((x) => x.bound === b)),
            }))
            .concat([
              { bound: 'Total', data: groupByKM(data.filter((x) => ['NB', 'SB', 'WB', 'EB'].includes(x.bound))) },
            ]);
        };

        // Group NSE data by NSE sections (E1, E2, NKVE) and by bound
        const groupNSE = (data) => {
          const sects = {
            E1: ['N1', 'N2', 'N3', 'N4', 'N5', 'C1', 'C2', 'C3', 'C4H'],
            E2: ['C5', 'S1', 'S2', 'S3', 'S4', 'S5[K]', 'S5C', 'S5[D]'],
            NKVE: ['C4 NKVE'],
          };
          const res = {};
          for (const key in sects) {
            const all = [].concat(
              ...sects[key].map((s) => data.filter((x) => x['InspectionFile.Inspection.Asset.Section.name'] === s)),
            );
            res[key] = ['NB', 'SB', 'WB', 'EB']
              .map((b) => ({
                bound: b === 'NB' ? 'NorthBound' : b === 'SB' ? 'SouthBound' : b === 'WB' ? 'WestBound' : 'EastBound',
                data: groupByKM(all.filter((x) => x.bound === b)),
              }))
              .concat([{ bound: 'Total', data: groupByKM(all.filter((x) => ['NB', 'SB', 'WB', 'EB'].includes(x.bound))) }]);
          }
          return res;
        };

        Api({
          endpoint: endpoints.getAnalyticsRoad(),
          data: { ...query },
          onSuccess: async (data) => {
            const src = prepareSource(data);
            let out;
            if (query.filterByNetworkId == 1 || query.filterByNetworkId === '1') {
              // NSE branch: first filter out NSE-only sections for a default bound grouping…
              const ex = ['N1', 'N2', 'N3', 'N4', 'N5', 'C1', 'C2', 'C3', 'C4H', 'C5', 'S1', 'S2', 'S3', 'S4', 'S5[K]', 'S5C', 'S5[D]', 'C4 NKVE'];
              const filt = src.filter(
                (x) => !ex.includes(x['InspectionFile.Inspection.Asset.Section.name']),
              );
              const def = groupByBound(filt);
              // …and further group NSE by section using the full source data.
              const nse = groupNSE(src);
              out = (['E1', 'E2', 'NKVE'].includes(sectionScatter) && countPavementNetworkSelected === 1)
                ? nse[sectionScatter]
                : def;
            } else {
              // Non-NSE: include all sections and group by bound
              out = groupByBound(src);
            }
            setData(out);
            setIsLoadingScatterData(false);
          },
          onFail: (err) => toast('error', err),
        });
      };

    const getDataByGroupStacked = (query, setData, staticGroup = null) => {
        setIsLoadingStackedData(true);
        Api({
            endpoint: endpoints.getAnalyticsRoad(),
            data: { ...query },
            onSuccess: async (data) => {
                const mutationData = await Promise.all(Object.keys(data.data).map(async (d) => {
                    const dataByKey = await Promise.all(data.data[d].map(dbk => ({ ...dbk, location: dbk['InspectionFile.Inspection.Asset.location']?.split('/')[0] })));
                    const groupedData = _.countBy(dataByKey, 'location');
                    const naCount = Object.keys(groupedData).reduce((total, key) => {
                        if (!['NB', 'SB', 'EB', 'WB'].includes(key)) {
                            return total + groupedData[key];
                        }
                        return total;
                    }, 0);
                    const id = !staticGroup ? d : staticGroup.find(sg => sg.value == d)?.label;
                    return {
                        id,
                        NorthBound: groupedData.NB ?? 0,
                        SouthBound: groupedData.SB ?? 0,
                        EastBound: groupedData.EB ?? 0,
                        WestBound: groupedData.WB ?? 0,
                        NA: naCount,
                        d: _.groupBy(dataByKey, 'location'),
                    };
                }));
                setData(mutationData);
                setIsLoadingStackedData(false);
            },
            onFail: (err) => toast('error', err),
        });
    };

    const tableDataMutation = (d) => ({
        ...d,
        annotation_type_name: d['DetectionType.name'],
        dimension: { width: 20, height: 40 },
        measurement: [
            { wcm: 20, hcm: 10 },
            { wcm: 25, hcm: 15 },
        ],
        latLong: `${d['InspectionFile.lat']},${d['InspectionFile.lng']}`,
        network: d['InspectionFile.Inspection.Asset.Network.label'],
        region: d['InspectionFile.Inspection.Asset.Region.name'],
        section: d['InspectionFile.Inspection.Asset.Section.name'],
        location_area_district: d['InspectionFile.Inspection.Asset.location'],
        detected_at: d.createdAt,
        action: [{ state: 'completed' }],
        image: `${process.env.REACT_APP_FD}/${d['InspectionFile.thumbnail']}`,
    });
    const getDataTable = (query, setData) => {
        setIsLoadingTableData(true);
        Api({
            endpoint: endpoints.getAnalyticsRoad(),
            data: { ...query },
            onSuccess: async (data) => {
                setData(data.data.all.map(tableDataMutation));
                setIsLoadingTableData(false);
            },
            onFail: (err) => toast('error', err),
        });
    };

    const getActions = (AnnotationId) => {
        if (!AnnotationId) return;
        setIsLoadingTakeAction(true);
        Api({
            endpoint: endpoints.getAnnotationActions(AnnotationId),
            onSuccess: ({ data }) => {
                setTakeActionList(data.reverse());
                setIsLoadingTakeAction(false);
            },
            onFail: (response) => console.log('lol'),
        });
    };

    const createNewAction = (params) => {
        setIsLoadingTakeAction(true);
        const data = { AnnotationId: selectedAnnotation?.id, ActionStatusId: params.status, remark: params.remark, takenAt: params.date };
        if (params.AnnotationActionId) data.AnnotationActionId = params.AnnotationActionId;
        Api({
            endpoint: endpoints.createOrUpdateAnnotations(),
            data,
            files: params.files,
            onSuccess: (res) => {
                toast('success', res.message);
                setIsLoadingTakeAction(false);
                getActions(data.AnnotationId);
            },
            onFail: (err) => toast('error', err),
        });
    };

    const onClickDetectionTypes = (typeId) => {
        if (typeId === 'all') {
            return setDetectionTypeSelected(['all'])
        }
        setDetectionTypeSelected(pv => (detectionTypeSelected.includes(typeId)
            ? (pv.filter(x => x !== typeId).length ? pv.filter(x => x !== typeId) : ['all'])
            : ([...pv.filter(x => x !== 'all'), typeId])));
    };
    const onClickDetection = (annotation) => {
        setSelectedDetection(!selectedDetection);
        setSelectedAnnotation(annotation)
    };
    const onBarOrPointClick = (data) => setTableFilterData(data.map(tableDataMutation));
    const onNoBarClick = () => setTableFilterData([]);
    const dts = detectionTypeSelected.includes('all') ? allDetectionTypes : detectionTypeSelected;

    useEffect(() => {
        let { dataSource, selectedKey, filteredData, year } = getDataByClick;
        if (filteredData) {
            onBarOrPointClick(filteredData);
            return;
        }
        if (dataSource == "summaryPavementData") {
            const [d, m, y] = selectedKey.split("/")
            onBarOrPointClick(summaryPavementDataAllValue[0][`${year}-${m}-${d}`]);
        }
        if (dataSource == "countNetworkData") {
            console.log("GG", countNetworkDataAllValue, selectedKey)
            if (selectedKey != 'N/A') selectedKey = selectedKey.toLowerCase()
            onBarOrPointClick(countNetworkDataAllValue[0][selectedKey]);
        }
        if (dataSource == "countRegionData") {
            console.log("GG", countRegionDataAllValue, selectedKey)
            if (selectedKey != 'N/A') selectedKey = capitalize(selectedKey.toLowerCase())
            onBarOrPointClick(countRegionDataAllValue[0][selectedKey]);
        }
        if (dataSource == "countSectionData") {
            selectedKey = selectedKey == "N/A" ? "Out Of Bound" : selectedKey
            console.log("GG", countSectionDataAllValue, `${selectedKey}s`)
            onBarOrPointClick(countSectionDataAllValue[0]?.[selectedKey]);
        }
    }, [getDataByClick])

    // Reset filter button when changing page
    useEffect(() => {
        setDetectionTypeSelected(['all'])
    }, [isPavement, tab])

    // To check all data finish fetching
    useEffect(() => {
        const anyLoading = isLoadingStaticData || isLoadingGroupData || isLoadingScatterData || isLoadingStackedData || isLoadingTableData;
        setIsLoading(anyLoading);
    }, [isLoadingStaticData, isLoadingGroupData, isLoadingScatterData, isLoadingStackedData, isLoadingTableData]);

    // Effects
    // 1. Call all statics
    useEffect(() => { getStaticData(); }, [location.pathname, tab]);
    // 2.  when filter summary pavement change
    useEffect(() => {
        const query = {};
        if (!summaryPavementNetworkSelected) return;
        query.filterByNetworkId = summaryPavementNetworkSelected ?? networks.map(n => n.id).join(',');
        query.filterByDetectionTypeId = dts.length ? dts.join(',') : detectionTypes.map(n => n.value).join(',');
        query.filterByDateRange = `${addDay(summaryPavementDateSelected.startDate, 1).toISOString().split('T')[0]},${addDay(summaryPavementDateSelected.endDate, 1).toISOString().split('T')[0]}`;
        query.groupBy = 'date';
        console.log('summary pavement change', query);
        getDataByGroup(query, setSummaryPavementData, setSummaryPavementDataAllValue);
    }, [detectionTypeSelected, summaryPavementNetworkSelected, summaryPavementDateSelected]);
    // 3.  when filter count pavement change
    useEffect(() => {
        const query = {};
        if (!countPavementNetworkSelected) return;
        if (countPavementNetworkSelected !== 1) setSectionScatter('E1');
        query.filterByNetworkId = countPavementNetworkSelected ?? networks.map(n => n.id).join(',');
        query.filterByDetectionTypeId = dts.length ? dts.join(',') : detectionTypes.map(n => n.value).join(',');
        query.filterByDateRange = `${addDay(countPavementDateSelected.startDate, 1).toISOString().split('T')[0]},${addDay(countPavementDateSelected.endDate, 1).toISOString().split('T')[0]}`;
        console.log('count pavement change', query);
        getDataScatter(query, setCountPavementData);
    }, [detectionTypeSelected, countPavementNetworkSelected, countPavementDateSelected, sectionScatter]);
    // 4.  when filter count network change
    useEffect(() => {
        const query = {};
        if (!networks.length) return; // wait for static data
        query.filterByDetectionTypeId = dts.length ? dts.join(',') : detectionTypes.map(n => n.value).join(',');
        query.filterByDateRange = `${addDay(countNetworkDateSelected.startDate, 1).toISOString().split('T')[0]},${addDay(countNetworkDateSelected.endDate, 1).toISOString().split('T')[0]}`;
        query.groupBy = 'network';
        console.log('count network change', query);
        getDataByGroup(query, setCountNetworkData, setCountNetworkDataAllValue, networks);
        getDataByGroupStacked(query, setCountNetworkDataStacked, networks);
    }, [detectionTypeSelected, countNetworkDateSelected, networks]);
    // 5.  when filter count region change
    useEffect(() => {
        const query = {};
        if (!regions.length) return; // wait for static data
        query.filterByDetectionTypeId = dts.length ? dts.join(',') : detectionTypes.map(n => n.value).join(',');
        query.filterByDateRange = `${addDay(countRegionDateSelected.startDate, 1).toISOString().split('T')[0]},${addDay(countRegionDateSelected.endDate, 1).toISOString().split('T')[0]}`;
        query.groupBy = 'region';
        console.log('count region change', query);
        getDataByGroup(query, setCountRegionData, setCountRegionDataAllValue, regions);
        getDataByGroupStacked(query, setCountRegionDataStacked, regions);
    }, [detectionTypeSelected, countRegionDateSelected, regions]);
    useEffect(() => {
        const query = {};
        if (!sections.length) return; // wait for static data
        query.filterByDetectionTypeId = dts.length ? dts.join(',') : detectionTypes.map(n => n.value).join(',');
        query.filterByDateRange = `${addDay(countSectionDateSelected.startDate, 1).toISOString().split('T')[0]},${addDay(countSectionDateSelected.endDate, 1).toISOString().split('T')[0]}`;
        query.groupBy = 'section';
        console.log('test count section change', query);
        getDataByGroup(query, setCountSectionData, setCountSectionDataAllValue, sections);
    }, [detectionTypeSelected, countSectionDateSelected, sections]);
    useEffect(() => {
        const query = {};
        query.filterByDetectionTypeId = dts.length ? dts.join(',') : detectionTypes.map(n => n.value).join(',');
        query.filterByDateRange = `${addDay(startDate, 1).toISOString().split('T')[0]},${addDay(endDate, 1).toISOString().split('T')[0]}`;
        console.log('table data change', query);
        getDataTable(query, setTableData);
    }, [detectionTypeSelected]);

    useEffect(() => {
        getActions(selectedAnnotation?.id);
    }, [selectedAnnotation]);

    return {
        // loader state
        isLoading,
        setIsLoading,

        // static state
        tabList,
        networks,
        sections,
        regions,
        detectionTypes,

        // global actions state
        tab,
        setTab,
        detectionTypeSelected,
        setDetectionTypeSelected,
        onClickDetectionTypes,
        onBarOrPointClick,
        onNoBarClick,

        // chart action state
        summaryPavementNetworkSelected,
        setSummaryPavementNetworkSelected,
        summaryPavementDateSelected,
        setSummaryPavementDateSelected,
        summaryPavementDateSelectedActive,
        setSummaryPavementDateSelectedActive,

        countPavementNetworkSelected,
        setCountPavementNetworkSelected,
        countPavementDateSelected,
        setCountPavementDateSelected,
        countPavementDateSelectedActive,
        setCountPavementDateSelectedActive,
        sectionScatter,
        setSectionScatter,

        countRegionDateSelected,
        setCountRegionDateSelected,
        countNetworkDateSelectedActive,
        setCountNetworkDateSelectedActive,
        countNetworkDateSelected,
        setCountNetworkDateSelected,
        countRegionDateSelectedActive,
        setCountRegionDateSelectedActive,

        countSectionDateSelected,
        setCountSectionDateSelected,
        countSectionDateSelectedActive,
        setCountSectionDateSelectedActive,

        // chart data mutation
        summaryPavementData,
        countPavementData,
        countNetworkData,
        countRegionData,
        countNetworkDataStacked,
        countRegionDataStacked,
        countSectionData,

        // table data
        tableData,
        tableFilterData,

        onClickDetection,
        selectedDetection,
        selectedAnnotation,
        page,
        setPage,
        perpage,
        setPerpage,
        isPavement,
        getDataByClick,
        setGetDataByClick,
        takeActionList,
        isLoadingTakeAction,
        setIsLoadingTakeAction,
        isCollapse,
        setIsCollapse,
        createNewAction,
    };
}
