import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  FormControl,
  Select,
  IconButton,
} from "@material-ui/core";
import { Sort } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  dropdownContainer: {
    position: "relative",
  },
  formControlContainer: {
    height: "0",
    position: "absolute",
    top: 30,
    left: 2,
    width: "100%",
  },
  formControl: {
    width: "100%",
    visibility: "hidden",
    padding: 0,
  },
}));

const StyledMenuItem = withStyles(() => ({
  root: {
    justifyContent: "center",
    backgroundColor: "var(--dark-blue-color)",
    "& h1, h2, h3, h4, h5, h6": {
      textAlign: "center",
      color: "white",
    },
    "&:focus, &:hover": {
      backgroundColor: "white",
      "& h1, h2, h3, h4, h5, h6": {
        color: "var(--dark-blue-color)",
      },
    },
  },
}))(MenuItem);

export default function Dropdown({
  icon,
  selectedItem = 0,
  setSelectedItem,
  itemList,
  Hx = "h3",
  xtraText = "",
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setSelectedItem(event.target.value);
    setAnchorEl(null);
  };

  return (
    <div className={classes.dropdownContainer}>
      <IconButton
        onClick={handleClick}
        style={{ color: "var(--primary-color)" , padding:0}}
      >
        {icon}
      </IconButton>
      <div className={classes.formControlContainer}>
        <FormControl className={classes.formControl}>
          <Select
            open={Boolean(anchorEl)}
            onClose={handleClose}
            value={selectedItem}
            onChange={handleChange}
            MenuProps={{
              getContentAnchorEl: null,
              MenuListProps: { disablePadding: true },
            }}
          >
            {itemList?.map((m, i) => (
              <StyledMenuItem
                key={i}
                selected={selectedItem === i}
                value={i}
                dense
              >
                <Hx>{m}</Hx>
              </StyledMenuItem>
            ))}
            {!!xtraText && (
              <StyledMenuItem
                selected={selectedItem >= itemList.length}
                value={itemList.length}
                dense
              >
                <Hx>{xtraText}</Hx>
              </StyledMenuItem>
            )}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
