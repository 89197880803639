/* eslint-disable max-nested-callbacks */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import queryString from 'query-string';

export default function Hook({ project }) {
  const project_id = queryString.parse(window.location.search).id;
  const [modelList, setModelList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [keys, setKeys] = useState('cycle');
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [justUpdated, setJustUpdated] = useState(0);

  const refresh = () => {
    if (!project?.id || !project_id) return;
    setIsLoading(true);
    const data = { paginate: true };
    data.page = page;
    data.perpage = perPage;
    data.filter = `AssetId,${project?.id ?? project_id}${(!!keys && keyword) && `,${keys},${keyword}`}`;
    Api({
      endpoint: endpoints.getAugmentedModel(),
      data,
      onSuccess: (response) => {
        setTotalData(response.total);
        setModelList(response.data);
        setIsLoading(false);
      },
      onFail: setModelList([]),
    });
  };

  useEffect(() => {
    if (!project?.id || !project_id) return;
    refresh();
  }, [project, perPage, page, justUpdated]);

  const deleteARModel = (id) => {
    if (!id) return;
    setIsLoading(true);
    Api({
      endpoint: endpoints.deleteAugmentedModel(id),
      onSuccess: () => {
        setPage(prev => {
          if (!!(modelList.length - 1)) return prev;
          return (!!(prev - 1) ? prev - 1 : 1);
        });
        toast('success', 'Successfully deleted');
        setIsLoading(false);
        setJustUpdated(prev => prev + 1);
      },
      onFail: () => {
        toast('error', 'Failed to delete augmented model');
        setIsLoading(false);
      },
    });
  };

  const createARModel = ({ name, files }) => {
    if (!project_id) return;
    if (!name) return;
    if (!files) return;
    setIsLoading(true);
    Api({
      endpoint: endpoints.createAugmentedModel(),
      data: { name, AssetId: project_id },
      files: files,
      onSuccess: () => {
        refresh();
        toast('success', 'Successfully created');
        setIsLoading(false);
      },
      onFail: () => {
        toast('error', 'Failed to create augmented model');
        setIsLoading(false);
      },
    });
  };
  const updateAugmentedModel = (data, modelId) => {
    Api({
      endpoint: endpoints.updateAugmentedModel(modelId),
      data,
      onSuccess: () => {
        refresh();
        toast('success', 'Successfully updated');
      },
      onFail: () => toast('error', 'Failed to update augmented model'),
    });
  };

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') {
      setPage(1);
      refresh();
    }
  };
  const onCloseSearch = () => { setKeyword(''); refresh(); };


  useEffect(() => {
    setPage(1);
  }, [perPage]);

  return {
    deleteARModel,
    createARModel,
    isLoading,
    setIsLoading,
    updateAugmentedModel,
    project_id,
    keys,
    setKeys,
    keyword,
    setKeyword,
    page,
    setPage,
    perPage,
    setPerPage,
    onKeyDown,
    onCloseSearch,
    totalData,
    refresh,
    modelList,
  };
}
