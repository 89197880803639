/* eslint-disable complexity */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable indent */
import { Grid, Box } from '@material-ui/core';
import StackedBar from '@Components/StackedBar';
import BarChart from '@Components/BarChart/v6';
import GradientTabs from '@Components/GradientTabs';
import ScatterChart from '@Components/ScatterChart';
import Select from '@Components/Select';
import NoData from '@Assets/Images/Data-not-found3.png';
import DateRange from '@Components/DateRange';
import TableAnalytics from '@Components/TableAnalytics/TableAnalytics';
import Hook from './hook';
import { capitalize } from '@Helpers';
import FilterButtonList from '@Components/FilterButtonList';
import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import ScatterButton from '@Components/Button/ScatterButton';

const SectionHeader = ({ title, children, fontSize }) => (
  <Grid
    style={{
      display: 'flex',
      padding: '20px 23px 0px 20px',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'white',
    }}
  >
    <h1 style={{ color: 'black', fontWeight: 'bold', fontSize: fontSize ?? '22px' }}>
      {title}
    </h1>
    <Grid style={{ display: 'flex', gap: 20 }}>
      {children}
    </Grid>
  </Grid>
);

const ChartSection = ({
  title, data, label, colorfills, height = 325, width = 1200, hasDaysFilter,
  hasDataGroupFilter, fontSize, paddingChart, isScatterChart, isStackedBarChart, sizeLabelX,
  isCountByNetwork, type, barWidth, legendStyle, allowBarColor, networks, sections, regions,
  domainPadding, setGetDataByClick, dataSource, scatterChart, setScatterChart, angle, ...rest
}) => {
  const Chart = () => {
    let chartType = 'barChart';
    if (isStackedBarChart) chartType = 'stackBar';
    if (isScatterChart) chartType = 'scatter';
    switch (chartType) {
      case 'stackBar':
        return <StackedBar isCountByNetwork={isCountByNetwork} data={data} networks={networks} sections={sections} regions={regions} width={width} height={height} colorfills={colorfills}
          onClick={
            (e, ed) => {
              console.log("[CLICK DEBUG] StackedBar Clicked", ed.datum);
              let key = ed.datum.x == "N/A" ? "N/A" : dataSource == "countNetworkDataStacked" ? ed.datum.x.toLowerCase() : capitalize(ed.datum.x.toLowerCase());
              let objKey = [, "NB", "SB", "WB", "EB", "undefined"][ed.datum._stack]
              if (key == "N/A" && objKey == "undefined") objKey = "Out Of Bound"
              const filteredData = data.find(d => d.id == key).d[objKey]
              setGetDataByClick({ filteredData })
            }
          }
        />;
      case 'scatter':
        return <ScatterChart data={data} onClick={(e, ed) => {
          console.log("[CLICK DEBUG] Scatter Clicked", ed.datum);
          setGetDataByClick({ filteredData: ed.datum.d })
        }} />;
      default:
        return (
          <BarChart
            type={type}
            data={data}
            label={label}
            height={height}
            width={width}
            networks={networks}
            sections={sections}
            regions={regions}
            angle={angle}
            colorfills={colorfills}
            legendStyle={legendStyle}
            labelX={15}
            labelY={30}
            legendX={550}
            legendY={40}
            barWidth={barWidth}
            sizeLabelX={sizeLabelX}
            allowBarColor={allowBarColor}
            domainPadding={domainPadding}
            onClick={(e, ed) => {
              console.log("[CLICK DEBUG] Bar Clicked", ed.datum);
              if (ed.datum.d) {
                setGetDataByClick({ filteredData: ed.datum.d })
                return;
              }
              if (dataSource) {
                const selectedKey = ed.datum.x.replace(/\\n/g, " ").replace(/\n/g, " ").replace(/\[/g, " [").replace(/\(/g, " (");
                setGetDataByClick({ dataSource, selectedKey, year: ed.datum.year })
              }
            }}
            paddingChart={paddingChart ?? { bottom: 40, top: 0, left: 50, right: 50, }}
          />
        );
    }
  };
  return (
    <Grid item xs={12}
      style={{
        backgroundColor: 'white',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2)',
        borderRadius: '20px',
        overflow: 'hidden',
      }}>
      <SectionHeader title={title} fontSize={fontSize}>
        {isScatterChart && rest.countPavementNetworkSelected === 1 && <ScatterButton {...rest} />}
        {hasDataGroupFilter && <Select title="Network" {...hasDataGroupFilter} />}
        {hasDaysFilter && <DateRange {...hasDaysFilter} />}
      </SectionHeader>
      {
        (isScatterChart ? data[4]?.data.length : data.length)
          ? <Chart />
          : (
            <div className="d-flex justify-content-center nodata-chart">
              <img src={NoData} alt="No Data" />
            </div>
          )
      }
    </Grid>
  );
};

export default (props) => {
  const h = Hook(props);
  return (
    h.isLoading ? (<CenteredLoadingContainer height="70vh" size={75} hasText text="analytics" />)
      : (<>
        <h1 className="text-title" style={{ marginTop: 35, marginBottom: h.isPavement && 20 }}>
          {h.isPavement ? 'Analytics - Pavement' : 'Analytics - Traffic Safety'}
        </h1>
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <Box width="100%">
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item xs={12} style={{ marginBottom: h.isPavement && 20 }}>
                {h.isPavement && <GradientTabs tabs={h.tabList} tab={h.tab} setTab={h.setTab} />}
              </Grid>
              <FilterButtonList
                items={h.detectionTypes}
                selectedItems={h.detectionTypeSelected}
                onClickItem={h.onClickDetectionTypes}
                primaryColor='linear-gradient(90.17deg, #32A9D1 1.24%, #4CD2AE 99.85%)'
                marginLeft={8}
              />
              <Grid item xs={12} >
                <ChartSection
                  //Chart 1 Bar
                  {...h}
                  title={h.isPavement ? "Summary of Pavement-Related Detections" : "Summary of Traffic Safety-Related Detections"}
                  data={h.summaryPavementData.length ? h.summaryPavementData : []}
                  label={h.summaryPavementData.length ? Object.keys(h.summaryPavementData[0]).map(selector => ({ selector, name: selector })) : []}
                  hasDaysFilter={{
                    isDateEditing: h.summaryPavementDateSelectedActive,
                    setDateEditing: h.setSummaryPavementDateSelectedActive,
                    selectionRange: h.summaryPavementDateSelected,
                    setSelectionRange: h.setSummaryPavementDateSelected,
                  }}
                  hasDataGroupFilter={{ selected: h.summaryPavementNetworkSelected, setSelected: h.setSummaryPavementNetworkSelected, itemList: h.networks }}
                  domainPadding={{ x: [50, 50], y: [50, 50] }}
                  paddingChart={{ bottom: 40, top: 30, left: 50, right: 50, }}
                  allowBarColor
                  height={260}
                  dataSource={"summaryPavementData"}
                  angle={-40}
                />
              </Grid>
              <Grid item xs={12}>
                <ChartSection
                  //Chart 2 Scatter
                  {...h}
                  title={h.isPavement ? "Count of Pavement-Related Detection by KM" : "Count of Traffic Safety-Related Detection by KM"}
                  data={h.countPavementData.length ? h.countPavementData : []}
                  isScatterChart
                  hasDaysFilter={{
                    isDateEditing: h.countPavementDateSelectedActive,
                    setDateEditing: h.setCountPavementDateSelectedActive,
                    selectionRange: h.countPavementDateSelected,
                    setSelectionRange: h.setCountPavementDateSelected,
                  }}
                  hasDataGroupFilter={{ selected: h.countPavementNetworkSelected, setSelected: h.setCountPavementNetworkSelected, itemList: h.networks }}
                  height={260}
                />
              </Grid>
              <Grid item xs={6}>
                <ChartSection
                  //Chart 3 Bar
                  {...h}
                  title="Count by Network"
                  type='network'
                  fontSize={20}
                  sizeLabelX={20}
                  domainPadding={{ x: [50, 50], y: [50, 50] }}
                  paddingChart={{ top: 50, bottom: 60, left: 50, right: 50 }}
                  data={h.countNetworkData.length ? h.countNetworkData : []}
                  label={h.countNetworkData.length ? Object.keys(h.countNetworkData[0]).map(selector => ({ selector, name: selector })) : []}
                  hasDaysFilter={{
                    isDateEditing: h.countNetworkDateSelectedActive,
                    setDateEditing: h.setCountNetworkDateSelectedActive,
                    selectionRange: h.countNetworkDateSelected,
                    setSelectionRange: h.setCountNetworkDateSelected,
                  }}
                  dataSource={"countNetworkData"}
                  height={420}
                />
              </Grid>
              <Grid item xs={6}>
                <ChartSection
                  //Chart 4 Bar
                  {...h}
                  title="Count by Region"
                  type='region'
                  fontSize={20}
                  sizeLabelX={20}
                  domainPadding={{ x: [50, 50], y: [50, 50] }}
                  paddingChart={{ top: 50, bottom: 60, left: 50, right: 50 }}
                  data={h.countRegionData.length ? h.countRegionData : []}
                  label={h.countRegionData.length ? Object.keys(h.countRegionData[0]).map(selector => ({ selector, name: selector })) : []}
                  hasDaysFilter={{
                    isDateEditing: h.countRegionDateSelectedActive,
                    setDateEditing: h.setCountRegionDateSelectedActive,
                    selectionRange: h.countRegionDateSelected,
                    setSelectionRange: h.setCountRegionDateSelected,
                  }}
                  dataSource={"countRegionData"}
                  height={420}
                />
              </Grid>
              <Grid item xs={6}>
                <ChartSection
                  //Chart 5 Stacked Bar
                  {...h}
                  title="Count by Network"
                  isStackedBarChart
                  isCountByNetwork
                  fontSize={20}
                  height={420}
                  data={h.countNetworkDataStacked.length ? h.countNetworkDataStacked : []}
                  dataSource={"countNetworkDataStacked"}
                />
              </Grid>
              <Grid item xs={6}>
                <ChartSection
                  //Chart 6 Stacked Bar
                  {...h}
                  title="Count by Region"
                  isStackedBarChart
                  fontSize={20}
                  height={420}
                  data={h.countRegionDataStacked.length ? h.countRegionDataStacked : []}
                  dataSource={"countRegionDataStacked"}
                />
              </Grid>
              <Grid item xs={12}>
                <ChartSection
                  //Chart 7 Bar section
                  {...h}
                  title="Count by Section"
                  type='section'
                  allowBarColor
                  fontSize={22}
                  data={h.countSectionData.length ? h.countSectionData : []}
                  label={h.countSectionData.length ? Object.keys(h.countSectionData[0]).map(selector => ({ selector, name: selector })) : []}
                  width={1200}
                  barWidth={18}
                  height={280}
                  domainPadding={{ x: [10, 10], y: [50, 50] }}
                  paddingChart={{ bottom: 40, top: 30, left: 50, right: 50, }}
                  hasDaysFilter={{
                    isDateEditing: h.countSectionDateSelectedActive,
                    setDateEditing: h.setCountSectionDateSelectedActive,
                    selectionRange: h.countSectionDateSelected,
                    setSelectionRange: h.setCountSectionDateSelected,
                  }}
                  legendStyle={[
                    { label: 'NSE', color: '#AEC9FE' },
                    { label: 'SPDH', color: '#F6A6FF' },
                    { label: 'NSECL', color: '#85E3FF' },
                    { label: 'MSSC', color: 'orange' },
                    { label: 'PB', color: '#BDFCC3' },
                    { label: 'BKE', color: '#E7FFA9' },
                    { label: 'LPT2', color: '#30A6D3' },
                    { label: 'N/A', color: 'green' },
                  ]}
                  dataSource={"countSectionData"}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
                <TableAnalytics tableList={h.tableFilterData.length ? h.tableFilterData : h.tableData} isFiltered={!!h.tableFilterData.length} h={h} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>)
  );
};
