import React from 'react';
import {
  Close, Publish, Description,
} from '@material-ui/icons';
import { IconButton, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './dropzone.css';
import clsx from 'clsx';
import { truncateFilenameString } from '@Helpers';
import useHook from './hook';

export default function Dropzone({
  files, setFiles, height, progress, ...props
}) {
  const classes = useStyles();
  const { getRootProps, getInputProps, open } = useHook({ files, setFiles, ...props });
  const isChildCustomCenter = clsx('dropzone d-flex flex-column my-auto noData', classes.noData);
  const isCustomStyle = height ? { height } : {};

  const labelExtension = {
    'compress': 'ZIP OR RAR',
    'pdfImg': 'JPEG OR PDF',
  }[props.type] || '';

  return (
    <>
      <div
        style={{
          ...{
            border: '1px solid #ced4da',
            borderRadius: 10,
            height: 700,
            padding: 5,
            ...props.customStyle,
          },
          ...isCustomStyle,
        }}
        className="d-flex flex-column"
      >
        <div
          {...getRootProps({ className: isChildCustomCenter })}
        >
          <input {...getInputProps()} />
          <>
            {' '}
            <div style={{ textAlign: 'center' }}>
              <Publish />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <p style={{ color: 'var(--dark-blue-color)' }}>Drag &amp; Drop your file here or</p>
              <p style={{ color: '#7fb9f7', fontWeight: 600, cursor: 'pointer' }} onClick={open}>&nbsp;Choose File&nbsp;</p>
              <p style={{ color: 'var(--dark-blue-color)' }}>to upload</p>
            </div>
            <p style={{ color: '#ADB5BD' }}>{labelExtension}</p>
          </>
        </div>
      </div>
      {files?.length > 0 && (
        <div
          style={{
            ...{
              borderRadius: 10,
              height: 700,
              padding: 5,
              ...props.customStyle,
            },
            ...isCustomStyle,
            backgroundColor: '#FAF9F6',
          }}
        >
          {files.map((file, fileidx) => {
            console.log('xxx file', file);
            return (
              <div>
                <div className="d-flex" style={{ width: '100%' }}>
                  <div key={file.name} style={{ margin: 5 }}>
                    {file.type == 'image/png' ? (
                      <img
                        src={file.preview}
                        style={{
                          width: '100%',
                          height: '100px',
                          objectFit: 'contain',
                        }}
                        alt="uploaded"
                      />
                    ) : (
                      <Description style={{ fontSize: 50 }} />
                    )}
                  </div>
                  <div style={{ margin: 5, display: 'flex', width: '100%' }}>
                    <div style={{ width: '50%' }}>
                      <p style={{ fontSize: '0.9em', color: 'black', fontWeight: 600, display: 'flex', flex: 'start', width: '100%' }}>
                        {truncateFilenameString(file.name, 50)}
                      </p>
                      <p style={{ fontSize: '0.9em', color: 'grey', display: 'flex', flex: 'start', width: '100%' }}>
                        {file.size / 1000} kb
                      </p>
                    </div>
                  </div>
                  <div>
                    <IconButton onClick={() => setFiles(files.filter((xx, idx) => idx !== fileidx))} disableRipple style={{ backgroundColor: 'transparent', padding: 0 }}>
                      <Close style={{ width: 20 }} />
                    </IconButton>
                  </div>
                </div>
                <div style={{ position: 'relative', top: 5 }}>
                  <p style={{ fontSize: '0.9em', color: 'black', display: 'flex', justifyContent: 'end' }}>
                    {progress} %
                  </p>
                  <LinearProgress variant="determinate" value={progress} className={classes.progressbar} style={{ width: '100%', borderRadius: 30, height: 8 }} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  noData: { margin: '100px 0px', top: '25%' },
  progressbar: {
    background: 'white',
    '& .MuiLinearProgress-bar': {
      backgroundColor: 'var(--primary-color)',
    },
  },
}));
