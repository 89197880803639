import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@material-ui/core';
import FileDropZone from '@Components/DropzoneBox';
import moment from 'moment';

const TakeActionDialog = (h) => {
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(1);
  const [date, setDate] = useState(moment().format('YYYY-MM-DDTHH:mm:ss'));
  const [remark, setRemark] = useState('');

  const addNewAction = () => {
    const newAction = { files, status, date: moment(date).toISOString(), remark }
    // h.setTakeActionList(prev => [...prev, newAction])
    h.createNewAction(newAction);
    closeForm();
  };

  const resetForm = () => {
    setStatus(1);
    setFiles([]);
    setDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
    setRemark('');
  };

  const closeForm = () => {
    h.setOpenActionDialog(pv => !pv);
    resetForm();
  };

  useEffect(() => {
    resetForm();
  }, [h.openActionDialog]);

const statusList = [
  { id: 1, label: 'In-Progress' },
  { id: 2, label: 'Completed (Temporary)' },
  { id: 3, label: 'Completed (Permanent)' },
];

  return (
    <Dialog
      open={h.openActionDialog}
      onClose={closeForm}
      PaperProps={{
        style: {
          padding: '15px',
          borderRadius: '20px',
        },
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <h3
          style={{
            color: '#7E7C7C',
            textAlign: 'center',
            fontWeight: 700,
          }}>
          Changing this detection to: {status === 1 ? 'In-Progress' : 'Completed'}
        </h3>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          fullWidth
          className="my-2"
          select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          label="Status"
        >
          {statusList.map(stat => (
            <MenuItem value={stat.id}>
              {stat.label}
            </MenuItem>
          ))}
        </TextField>
        <FileDropZone files={files} setFiles={setFiles} height={200} type="image" />
        <TextField
          variant="outlined"
          label="Date"
          type="datetime-local"
          className='mt-2'
          fullWidth
          value={date}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ step: 1 }}
        />
        <TextField
          variant="outlined"
          label="Remark"
          defaultValue=""
          multiline
          rowsMax={4}
          className='mt-2'
          fullWidth
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeForm}
          style={{
            backgroundColor: '#C9C8C8',
            color: 'white',
            borderRadius: '20px',
            fontSize: 11,
            padding: 8,
            fontWeight: 700
          }}>
          Close
        </Button>
        <Button
          onClick={addNewAction}
          disabled={!(!!files.length && !!remark)}
          autoFocus
          style={{
            backgroundColor: !!files.length && !!remark ? '#0061AA' : '#C9C8C8',
            color: 'white',
            borderRadius: '20px',
            fontSize: 11,
            padding: 8,
            fontWeight: 700
          }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export default TakeActionDialog;