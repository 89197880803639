/* eslint-disable max-len */
import { useState, useEffect, useRef } from 'react';
import {
  Grid, Card, Box, CardContent, Typography, CardMedia, Divider, TextField,
} from '@material-ui/core';
import { Sort } from '@material-ui/icons';
import { BigConeIcon } from '@Assets/Icons/cone';
import moment from 'moment';
import Dropdown from '@Components/DropdownIcon';

export default function SidebarDetection(props) {
  const containerRef = useRef();

  const handleScroll = () => {
    if (
      containerRef.current &&
      containerRef.current.scrollTop + containerRef.current.clientHeight >= containerRef.current.scrollHeight - 5
    ) {
      if (props.detections.length < props.detectionsTotal) {
        props.setDetectionPage(prev => prev + 1);
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      if (props.detections.length > 0 && props.detectionsTotal > 0) {
        handleScroll();
      }
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [props.detections, containerRef, props.detectionsTotal]);

  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.scrollTop = 0;
  }, [props.filteredDetectionTypes, props.selectedAnnotationFilterType]);

  const numberPlate = {
    position: 'absolute',
    bottom: '0%',
    left: '50%',
    fontSize: '14px',
    color: 'white',
    width: '100%',
    fontWeight: 'bolder',
    textAlign: 'center',
    zIndex: 2,
  };

  return (
    <>
      <Grid
        item xs={12}
        md={3}
        id="top"
        className="sidebar position-relative mt-4"
        style={{
          zIndex: 1,
          border: props.parentProps.isMap && '3px solid #32AAD2',
        }}
      >
        <div className="d-flex justify-content-center flex-wrap" style={{ flex: 1 }}>
          <div className='d-flex justify-content-between w-100 p-3'>
            <h2 style={{ color: 'black', fontWeight: 'bolder', fontSize: 18 }}>Detection List</h2>
            <TextField value={props.searchAnnotationById} onChange={(e) => props.setSearchAnnotationById(e.target.value)} placeholder='Annotation Id' size='small' style={{ width: '40%' }} onKeyDown={props.onKeyDown} />
            <Dropdown
              icon={<Sort />}
              selectedItem={props.selectedAnnotationFilterType}
              setSelectedItem={props.setSelectedAnnotationFilterType}
              itemList={props.annotationTableList}
              propertyValue="id"
              size="small"
              isNonIndexId={false}
              Hx="h6"
            />
          </div>
          {!!props.detections.length && (
            <>
              <Card className='pointer' onClick={() => props.onClickDetection(props.detections[0].id)}>
                <Box>
                  <Box style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                    <CardMedia
                      component="img"
                      style={{ width: '100%', position: 'relative' }}
                      image={`${process.env.REACT_APP_FD}/${props.detections[0].InspectionFile.thumbnail}`}
                      alt="Live from space album cover"
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        height: '4vh',
                        width: '100%',
                        zIndex: 1,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      }}
                    />
                    <h1 style={{ ...numberPlate, transform: 'translate(-50%, -55%)' }}>
                      {props.detections[0]?.InspectionFile?.Device?.label}
                    </h1>
                  </Box>
                  <CardContent>
                    <div className='d-flex w-100 justify-content-between align-items-center mb-1'>
                      <h2 className='text-uppercase' style={{ fontWeight: 'bolder', color: 'black', fontSize: 14 }}>
                        {props.detections[0].detectionType.name}
                      </h2>
                      <BigConeIcon />
                    </div>
                    <div className='d-flex w-100 justify-content-between'>
                      <p style={{ fontWeight: 'bolder', color: 'black', fontSize: 12 }}>
                        {props.networks.find(e => e.id === props.detections[0]?.InspectionFile?.Inspection?.Asset?.NetworkId)?.label.toUpperCase()}{' '}
                        ({props.sections.find(e => e.id === props.detections[0]?.InspectionFile?.Inspection?.Asset?.SectionId)?.name.toUpperCase()}-
                        {props.detections[0].InspectionFile.Inspection.Asset.name})
                      </p>
                      <p style={{ color: 'black', fontSize: 12 }}>
                        {moment(props.detections[0].createdAt).format('DD MMM YYYY')}
                      </p>
                    </div>
                    <div className='d-flex w-100 justify-content-between'>
                      <p style={{ fontSize: 12 }} className='text-secondary'>
                        {moment(props.detections[0].createdAt).format('hh:mm A')}
                      </p>
                      <p style={{ fontSize: 12 }} className='text-secondary'>
                        {props.detections[0].id}
                      </p>
                    </div>
                  </CardContent>
                </Box>
              </Card>
              <Divider />
              <div ref={containerRef} style={{ maxHeight: '40vh', minHeight: '30vh', overflowY: 'auto', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', backgroundColor: '#ffffff' }}>
                {props.detections.map((d, idx) => (
                  <Card onClick={() => props.onClickDetection(d.id)} key={d.id}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: props.selectedDetection?.id === d.id ? '#32AAD2' : (idx % 2 ? 'white' : '#F5FAFF'),
                        cursor: 'pointer',
                      }}
                    >
                      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <CardMedia
                          component="img"
                          style={{ padding: 10 }}
                          image={`${process.env.REACT_APP_FD}/${d.InspectionFile.thumbnail}`}
                          alt="Detection thumbnail"
                        />
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '10px',
                            left: '10px',
                            height: '3vh',
                            width: '100%',
                            zIndex: 1,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          }}
                        />
                        <h1 style={{ ...numberPlate, transform: 'translate(-45%, -80%)' }}>
                          {d.InspectionFile?.Device?.label}
                        </h1>
                      </div>
                      <CardContent className="w-75 p-2" style={{ marginLeft: '20px' }}>
                        <div className="d-flex w-100 justify-content-between align-items-center">
                          <p className="text-uppercase" style={{ fontWeight: 'bolder', color: 'black', fontSize: 13 }}>
                            {d.detectionType.name}
                          </p>
                          <BigConeIcon />
                        </div>
                        <hr className="my-1" />
                        <div className="d-flex w-100 justify-content-between">
                          <p style={{ fontWeight: 'bolder', color: 'black', fontSize: 11 }}>
                            {props.networks.find(e => e.id === d?.InspectionFile?.Inspection?.Asset?.NetworkId)?.label.toUpperCase()} ({props.sections.find(e => e.id === d?.InspectionFile?.Inspection?.Asset?.SectionId)?.name.toUpperCase()}-{d.InspectionFile.Inspection.Asset.name})
                          </p>
                        </div>
                        <div className="d-flex w-100 justify-content-between">
                          <p style={{ color: 'black', fontSize: 11 }}>{moment(d.createdAt).format('DD MMM YYYY')}</p>
                        </div>
                        <p className="text-secondary" style={{ fontSize: 11 }}>{moment(d.createdAt).format('hh:mm A')}</p>
                        <p className="text-secondary text-right" style={{ fontSize: 10 }}>{d.id}</p>
                      </CardContent>
                    </Box>
                    <Divider />
                  </Card>
                ))}
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
}
