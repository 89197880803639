import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import MainContentContainer from '@Components/MainContentContainer';
import Table from '@Components/MaterialTable/v9';
import { Button, Grid, IconButton } from '@material-ui/core';
import DataNotFoundImg from '@Assets/Images/Data-not-found3.svg';
import SortDropdown from '@Components/SortDropdown';
import { ExpandMore, GetApp, Refresh } from '@material-ui/icons';
import DeleteDialog from '@Components/DeleteDialog';
import RestoreDialog from '@Components/RestoreDialog';
import useHook from './hook';
import GradientTabs from '@Components/GradientTabs';
import GenerateReportDialog from './Components/GenerateReportDialog';

const titleStyle = {
  fontWeight: 'bold',
  fontSize: '30px',
  color: 'black',
};

export default function ReportList() {
  const h = useHook();
  return (
    <MainContentContainer style={{ marginTop: '1rem', backgroundColor: '#f5faff' }}>
      <div className="d-flex" style={{ justifyContent: 'space-between' }}>
        <h1
          className="my-auto pb-2"
          style={titleStyle}
        >
          {h.showDeleted ? 'Deleted Report' : 'Report List'}
        </h1>
      </div>
      <div className='mt-2 mb-3'>
        <GradientTabs tabs={h.tabList} tab={h.tab} setTab={h.setTab} />
      </div>
      <Grid container spacing={1}>
        {h.isLoading ? <CenteredLoadingContainer height="50vh" size={75} hasText text="reports" /> : (
          <>
            <Grid item xs={12} className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {[
                  { selected: h.selectedCycleFilter, setSelected: h.setSelectedCycleFilter, data: h.cycle, title: 'Cycle', isShow: ['slope', 'bridge'].includes(h.tab) },
                  { selected: h.selectedYearFilter, setSelected: h.setSelectedYearFilter, data: h.yearList, title: 'Year', isShow: true },
                  { selected: h.selectedNetworkFilter, setSelected: h.setSelectedNetworkFilter, data: h.networks, title: 'Network', isShow: true },
                  { selected: h.selectedRegionFilter, setSelected: h.setSelectedRegionFilter, data: h.regions, title: 'Region', isShow: true },
                  { selected: h.selectedSectionFilter, setSelected: h.setSelectedSectionFilter, data: h.sections, title: 'Section', isShow: true },
                  { selected: h.selectedRankingFilter, setSelected: h.setSelectedRankingFilter, data: h.rankings, title: 'Slope Hazard & Risk Ranking', isShow: ['slope'].includes(h.tab) },
                ].filter(e => !!e.isShow).map(sort => (
                  <SortDropdown {...sort} />
                ))}
                <IconButton className="p-0 pr-3">
                  <Refresh onClick={h.refresh} />
                </IconButton>
                {!!h.selectedRow?.length && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<GetApp />}
                    style={{ backgroundColor: 'var(--dark-blue-color)' }}
                    onClick={h.downloadMultipleFile}
                  >
                    Download
                  </Button>
                )}
              </div>
              <Grid className="d-flex justify-content-center">
                {(h.tab === 'pavement' || h.tab === 'trafficsafety') && (
                  <Button
                    onClick={() => h.setOpenGenerateReportDialog(!h.openGenerateReportDialog)}
                    variant="contained"
                    startIcon={<GetApp />}
                    style={{
                      background: 'linear-gradient(90.17deg, #32A9D1 1.24%, #4CD2AE 99.85%)',
                      color: 'white',
                      marginRight: 10,
                      borderRadius: 20,
                      fontSize: 11,
                    }}
                  >
                    Generate Report
                  </Button>
                )}
                <p
                  className="d-flex align-items-center"
                  style={{ fontSize: 14, color: '#0691fb', cursor: 'pointer' }}
                  onClick={() => h.setShowDeleted(prev => !prev)}
                >
                  {h.showDeleted ? (
                    'Back to Report'
                  ) : (
                    'Deleted History'
                  )}
                </p>
              </Grid>
            </Grid>
            <Grid container item xs={12} className="d-flex justify-content-center" style={{ borderRadius: 100 }}>
              {!!h.allReports?.length ? (
                <Grid item xs={12} style={{ borderRadius: 100 }}>
                  <Table tableData={h.filterReports} {...h} />
                </Grid>
              ) : <img src={DataNotFoundImg} style={{ width: '30vw' }} />}
            </Grid>
          </>
        )}
      </Grid>
      <GenerateReportDialog {...h} />
      <DeleteDialog
        open={h.openDialog}
        setOpen={h.setOpenDialog}
        selected={h.selectedReport}
        setSelected={h.setSelectedReport}
        deleteFunction={() => h.onDelete()}
      />
      <RestoreDialog
        open={h.openRestoreDialog}
        setOpen={h.setOpenRestoreDialog}
        selected={h.selectedReport}
        setSelected={h.setSelectedReport}
        restoreFunction={() => h.onRestoreReport()}
      />
    </MainContentContainer>
  );
}
