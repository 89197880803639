import Button from '@Components/Button';

const ScatterButton = ({ sectionScatter, setSectionScatter }) => {
    const isClicked = {
        background: 'linear-gradient(90.17deg, #32A9D1 1.24%, #4CD2AE 99.85%)',
        color: '#F6FBFF',
        fontWeight: 500,
        borderRadius: '20px',
        fontSize: 9,
        padding: '3px 5px',
        marginTop: '6px',
        marginLeft: '10px',
        minWidth: '40px',
    };
    const isNotClicked = {
        color: '#30A6D3',
        background: '#F6FBFF',
        fontWeight: 500,
        borderRadius: '20px',
        fontSize: 9,
        border: '1px solid #32AAD2',
        padding: '3px 5px',
        marginTop: '6px',
        marginLeft: '10px',
        minWidth: '40px',
    };
    return (
        <div>
            <Button onClick={() => { setSectionScatter('E1'); }}
                style={sectionScatter === 'E1' ? isClicked : isNotClicked}>
                E1
            </Button>

            <Button onClick={() => { setSectionScatter('E2'); }}
                style={sectionScatter === 'E2' ? isClicked : isNotClicked}>
                E2
            </Button>

            <Button onClick={() => { setSectionScatter('NKVE'); }}
                style={sectionScatter === 'NKVE' ? isClicked : isNotClicked}>
                NKVE
            </Button>
        </div>
    );
};

export default ScatterButton;
