import React, { useState } from 'react';
import {
  VictoryChart,
  VictoryScatter,
  VictoryAxis,
  VictoryLegend,
  VictoryTheme,
  VictoryLabel,
  VictoryTooltip,
} from 'victory';

const ScatterChart = ({ title, data, onClick }) => {
  const isClicked = {
    background: 'linear-gradient(90.17deg, #32A9D1 1.24%, #4CD2AE 99.85%)',
    color: '#F6FBFF',
    fontWeight: 500,
    borderRadius: '20px',
    fontSize: 9,
    padding: '3px 5px',
    marginTop: '6px',
    marginLeft: '10px',
    minWidth: '40px',
    border: 'none',
    outline: 'none',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  };

  const isNotClicked = {
    color: '#30A6D3',
    background: '#F6FBFF',
    fontWeight: 500,
    borderRadius: '20px',
    fontSize: 9,
    border: '1px solid #32AAD2',
    padding: '3px 5px',
    marginTop: '6px',
    marginLeft: '10px',
    minWidth: '40px',
  };

  const boundColors = {
    NorthBound: '#4c6ef5',
    SouthBound: '#FF00FF',
    WestBound: '#ffae42',
    EastBound: '#fff380',
    Total: '#6CC417',
  };

  const filteredData = data.filter((item) => item.data.length > 0);

  const [visibleBounds, setVisibleBounds] = useState(
    filteredData.map((item) => ({ bound: item.bound, visible: true })),
  );

  const toggleBoundVisibility = (bound) => {
    setVisibleBounds((prevState) => 
      prevState.map((item) => (item.bound === bound ? { ...item, visible: !item.visible } : item))
    );
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div style={{ marginBottom: '10px', marginLeft: '10px' }}>
        {visibleBounds.map((item) => (
          <button
            key={item.bound}
            onClick={() => toggleBoundVisibility(item.bound)}
            style={item.visible ? isClicked : isNotClicked}
          >
            {item.bound}
          </button>
        ))}
      </div>
      <VictoryChart height={260} width={1200} theme={VictoryTheme.material}>
        <VictoryLabel
          style={{ fontSize: 20, fill: '#1F3566', fontWeight: 600 }}
          x={60}
          y={25}
          text={title}
        />
        <VictoryLegend
          x={15}
          y={0}
          orientation="horizontal"
          gutter={20}
          data={Object.keys(boundColors).map((key) => ({
            name: key,
            symbol: { fill: boundColors[key] },
          })).filter((legendItem) => {
            const correspondingBound = data.find((y) => y.bound === legendItem.name);
            return !correspondingBound || correspondingBound.data.length > 0;
          })}
          style={{
            labels: { fontSize: 8, fontFamily: 'CeraProRegular' },
            data: { symbol: { size: 8 } },
          }}
        />
        <VictoryAxis
          style={{
            axisLabel: { padding: 30 },
            axis: { stroke: 'none' },
            grid: { stroke: '#e5e5e5', strokeDasharray: '4,4' },
            tickLabels: { fontFamily: 'CeraProRegular' },
          }}
          tickFormat={(t) => Math.round(t)}
        />
        <VictoryAxis
          dependentAxis
          style={{
            axisLabel: { padding: 40 },
            axis: { stroke: 'none' },
            grid: { stroke: 'none' },
            ticks: { stroke: '#e5e5e5', size: 5 },
            tickLabels: { fontFamily: 'CeraProRegular' },
          }}
          tickFormat={(t) => Math.round(t)}
        />
        {filteredData.map((x) => {
          const isVisible = visibleBounds.find((item) => item.bound === x.bound)?.visible;
          return (
            isVisible && (
              <VictoryScatter
                key={`scatter-${x.bound}`}
                data={x.data}
                size={3}
                style={{ data: { fill: boundColors[x.bound] } }}
                labels={({ datum }) => `${x.bound}, KM: ${datum.x}, Total: ${datum.y}`}
                labelComponent={(
                  <VictoryTooltip
                    cornerRadius={0}
                    flyoutStyle={{ stroke: '#ccc', fill: 'white' }}
                    constrainToVisibleArea
                  />
                )}
                events={[{ target: 'data', eventHandlers: { onClick } }]}
              />
            )
          );
        })}
      </VictoryChart>
    </div>
  );
};

export default ScatterChart;
