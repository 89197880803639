import moment from 'moment';
import React from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLabel, VictoryLegend, VictoryTheme, VictoryTooltip } from 'victory';

const GroupBarChart = ({
  height = 400, width = 400, offset = 0, data = [], legendX = 0, withDate = false, hasLegend = false,
}) => {
  const colorScale = ["#32AAD2", "#51DBA5", "#f0b334", "#d4371b"];
  const legendData = data.map((d, dIdx) => ({ name: d.type, symbol: { fill: colorScale[dIdx], type: "square" } }));
  const HasData = data.flatMap(d => d.data).some(d => d.y !== 0);
  return (
    <VictoryChart
      height={height}
      width={width}
      padding={{ top: 55, bottom: 150, left: 50, right: 40 }}
      domainPadding={{ x: 27 }}
    >
      <VictoryAxis
        dependentAxis
        crossAxis
        theme={VictoryTheme.material}
        style={{ grid: { stroke: 'grey', strokeOpacity: '50%' }, axis: { stroke: 'transparent' } }}
      />
      <VictoryAxis
        crossAxis
        theme={VictoryTheme.material}
        style={{
          tickLabels: {
            fill: ({ text }) => [moment().format('ddd'), moment().format('MMM')].includes(String(text)?.split('-')[0]) ? 'white' : 'black',
          }
        }}
        tickFormat={(t) => withDate ? t : (!!t ? String(t)?.replace(/ /g, '\n').replace(/\//g, '/\n') : '')}
        tickLabelComponent={<CustomTickLabel withDate={withDate} />}
      />
      {hasLegend && (
        <VictoryLegend
          x={legendX}
          y={10}
          centerTitle
          orientation="horizontal"
          gutter={20}
          style={{ labels: { fontSize: 18 } }}
          data={legendData}
        />
      )}
      {!!data.length && (
        <VictoryGroup
          offset={offset}
          colorScale={colorScale}
          domain={{ y: HasData ? undefined : [0, 10] }}
        >
          {data.map(e => (
            <VictoryBar
              data={HasData ? e.data : e.data.map(f => ({ ...f, y: 0.3 }))}
              cornerRadius={2}
              labels={({ datum }) => datum.y < 1 ? '' : datum.y}
              labelComponent={HasData ? <VictoryTooltip constrainToVisibleArea /> : undefined} />
          ))}
        </VictoryGroup>
      )}
    </VictoryChart>
  )
}

export default GroupBarChart;

const CustomTickLabel = ({ x, y, text, withDate, ...props }) => {
  const displayText = withDate ? String(text).replace('-', '\n') : text;

  const textPaddingX = 20; // Adjust padding around the text
  const textPaddingY = 10; // Adjust padding around the text
  const textStyle = { fontSize: 12 };

  // Split the text into lines based on line breaks
  const lines = displayText.split('\n');

  // Create a temporary canvas to measure text size
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `${textStyle.fontSize}px ${textStyle.fontFamily || 'inherit'}`;

  // Calculate the maximum line width
  const maxWidth = lines.reduce((max, line) => {
    const lineWidth = context.measureText(line).width;
    return lineWidth > max ? lineWidth : max;
  }, 0);

  const lineHeight = textStyle.fontSize * 1.2; // Approximate text height

  if ([moment().format('ddd'), moment().format('MMM')].includes(String(text).split('-')[0])) {
    return (
      <g>
        <rect
          x={x - maxWidth / 2 - textPaddingX / 2} // Center rect based on max line width
          y={y - lines.length * (lineHeight / (withDate ? 3 : 1.5)) - textPaddingY / 2 + 10} // Center rect based on total height
          width={maxWidth + textPaddingX}
          height={lines.length * lineHeight + textPaddingY}
          rx={5}
          ry={5}
          fill="#A7A7A7"
        />
        {lines.map((line, index) => (
          <VictoryLabel
            key={index}
            {...props}
            text={line}
            x={x}
            y={y - lines.length * (lineHeight / (withDate ? 2 : 10)) + index * lineHeight + (withDate ? 15 : 0)}
            textAnchor="middle"
          />
        ))}
      </g>
    )
  };

  return (
    <VictoryLabel
      {...props}
      text={displayText}
      x={x}
      y={y}
      textAnchor="middle"
    />
  );
};