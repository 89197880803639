/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-nested-callbacks */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { capitalizeAllWords, addDay } from '@Helpers';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

const defaultTableArray = [
  { x: 1, y: 0, fill: '#4FD8AF' },
  { x: 2, y: 0, fill: '#008FFB' },
  { x: 3, y: 0, fill: '#8B95AB' },
  { x: 4, y: 0, fill: '#FEB019' },
  { x: 5, y: 0, fill: '#EA1601' },
];

export default function Hook() {
  const [page, setPage] = useState(1);
  const today = new Date();
  const yesterday = (new Date()).setDate(today.getDate() - 1); // yestoru
  const startDate = new Date(today.getFullYear(), 0, 1); // first day of the month
  const endDate = today;
  const startWeek = (moment()).clone().startOf('week').format('YYYY-MM-DD');
  const endWeek = (moment()).clone().endOf('week').format('YYYY-MM-DD');
  const location = useLocation();
  const pageCondition = !!location.pathname.split('/')[2] ? location.pathname.split('/')[2] : 'Slope';
  const [asset, setAsset] = useState([]);
  const [filterAsset, setFilterAsset] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [sections, setSections] = useState([]);
  const [regions, setRegions] = useState([]);
  const [rankings, setRankings] = useState([]);
  const [bridgeType, setBridgeType] = useState([]);
  const [detectionType, setDetectionType] = useState([]);
  const [slopeCondition, setSlopeCondition] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(1);
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedRanking, setSelectedRanking] = useState([]);
  const [selectedBridgeType, setSelectedBridgeType] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [selected_project, set_selected_project] = useState(0);
  const [project, setProject] = useState({});
  const [slopeRating, setSlopeRating] = useState(defaultTableArray);
  const [overallSlope, setOverallSlope] = useState(defaultTableArray);
  const [title, setTitle] = useState('');
  const [tab, setTab] = useState(0);
  const [dataMonth, setDataMonth] = useState([]);
  const [dataWeek, setDataWeek] = useState([]);
  const [dataDay, setDataDay] = useState([]);
  const [trafficTimeframe, setTrafficTimeframe] = useState('weekly');
  const [filteredDetectionTypes, setFilteredDetectionTypes] = useState([]);
  const [emptySet, setEmptySet] = useState([]);
  const customFilterByType = pageCondition === 'slope' ? { RankingId: selectedRanking } : { bridge_type: selectedBridgeType };
  const filterType = {
    RegionId: selectedRegion, NetworkId: selectedNetwork, SectionId: selectedSection, ...customFilterByType,
  };

  const getDataByGroup = (query, setData, formatType) => {
    Api({
      endpoint: endpoints.getAnalyticsRoad(),
      data: { ...query },
      onSuccess: ({ data }) => {
        const dataGroups = [
          { type: 'NorthBound', data: [] },
          { type: 'SouthBound', data: [] },
          { type: 'EastBound', data: [] },
          { type: 'WestBound', data: [] },
        ];
        let filterDetection = [52, 55, 56, 57, 58, 59, 60, 61, 62, 63];
        if (pageCondition === 'pavement') filterDetection = (tab === 0 ? [52, 54, 76, 77, 78] : [64, 66, 79, 80, 81]);

        let arrBlank = [];
        if (formatType === 'month') arrBlank = [...Array(12)];
        if (formatType === 'week') arrBlank = [...Array(7)];
        if (formatType === 'detection') arrBlank = Object.keys(data);
        for (let mIdx = 0; mIdx < arrBlank.length; mIdx++) {
          let detections = []
          let x = moment().startOf('year').add(mIdx, 'months').format('MMM');
          let dataKey = x;
          if (formatType === 'month') {
            x = moment().startOf('year').add(mIdx, 'months').format('MMM');
            dataKey = `${moment().startOf('year').format('YYYY')}-${(`${mIdx}`.length > 1 ? `${mIdx + 1}` : `0${mIdx + 1}`)}`;
          }
          else if (formatType === 'week') {
            const dateInLoop = moment().startOf('isoWeek').add(mIdx, 'days');
            x = dateInLoop.format('ddd-(D/M)');
            dataKey = `${dateInLoop.format('YYYY-MM-DD')}`;
          }
          else if (formatType === 'detection') {
            x = detectionType.find(dt => dt.id == arrBlank[mIdx]).name; // TODO: get full name from static 
            dataKey = arrBlank[mIdx];
          }
          detections = data[`${dataKey}`] ?? [];
          if (detections.filter(e => filterDetection.includes(e['DetectionType.id'])).length) {
            const groupByBound = _.groupBy(detections.filter(e => filterDetection.includes(e['DetectionType.id'])), d => d['InspectionFile.Inspection.Asset.name'].split('/')[0]);
            dataGroups[0].data.push({ x, y: groupByBound['NB'] ? groupByBound['NB'].length : 0 })
            dataGroups[1].data.push({ x, y: groupByBound['SB'] ? groupByBound['SB'].length : 0 })
            dataGroups[2].data.push({ x, y: groupByBound['EB'] ? groupByBound['EB'].length : 0 })
            dataGroups[3].data.push({ x, y: groupByBound['WB'] ? groupByBound['WB'].length : 0 })
          } else {
            dataGroups[0].data.push({ x, y: 0 })
            dataGroups[1].data.push({ x, y: 0 })
            dataGroups[2].data.push({ x, y: 0 })
            dataGroups[3].data.push({ x, y: 0 })
          }
        }
        setData(dataGroups);
      },
      onFail: (err) => toast('error', err),
    });
  };

  useEffect(() => {
    if (!['pavement', 'traffic-safety'].includes(pageCondition)) return;
    if (_.isEmpty(project)) return;
    const query = {};
    query.filterByAssetTypeId = 3;
    if (!!project.id) query.filterByAssetId = project.id;
    query.filterByNetworkId = selectedNetwork;
    query.filterByDateRange = `${addDay(startDate, 1).toISOString().split('T')[0]},${addDay(endDate, 1).toISOString().split('T')[0]}`;
    query.groupBy = 'month';
    console.log('detection by month change', query);
    getDataByGroup(query, setDataMonth, 'month');
  }, [project, tab]);
  useEffect(() => {
    if (!['pavement', 'traffic-safety'].includes(pageCondition)) return;
    if (_.isEmpty(project)) return;
    const query = {};
    query.filterByAssetTypeId = 3;
    if (!!project.id) query.filterByAssetId = project.id;
    query.filterByNetworkId = selectedNetwork;
    query.filterByDateRange = `${startWeek},${endWeek}`;
    query.groupBy = 'date';
    console.log('detection by date change', query);
    getDataByGroup(query, setDataWeek, 'week');
  }, [project, tab]);
  useEffect(() => {
    if (!['pavement', 'traffic-safety'].includes(pageCondition)) return;
    if (_.isEmpty(project)) return;
    if (!detectionType.length) return;
    const query = {};
    query.filterByAssetTypeId = 3;
    if (!!project.id) query.filterByAssetId = project.id;
    query.filterByNetworkId = selectedNetwork;
    query.filterByDateRange = `${endDate.toISOString().split('T')[0]},${addDay(endDate, 1).toISOString().split('T')[0]}`;
    query.groupBy = 'detectionType';
    console.log('detection by dectection type change', query);
    getDataByGroup(query, setDataDay, 'detection');
  }, [detectionType, project, tab]);

  const refresh = () => {
    const query = {};
    let AssetTypeIdFilter = 1;
    switch (pageCondition) {
      case 'slope':
        AssetTypeIdFilter = 1;
        break;
      case 'bridge':
        AssetTypeIdFilter = 2;
        break;
      case 'pavement':
        AssetTypeIdFilter = 3;
        break;
      case 'traffic-safety':
        AssetTypeIdFilter = 3;
        break;
      default:
        AssetTypeIdFilter = 1;
        break;
    }
    query.page = page;
    query.perpage = 30;
    if (keyword.length) query.keyword = keyword;
    query.AssetTypeId = AssetTypeIdFilter; // can be dynamic
    if (!!selectedNetwork) query.filterByNetworkId = selectedNetwork;
    if (selectedSection.length) query.filterBySectionId = selectedSection.join(',');
    if (selectedRegion.length) query.filterByRegionId = selectedRegion.join(',');
    // END PAVEMENT CHARTS
    Api({
      endpoint: endpoints.getAssetsPaginated(),
      data: { ...query },
      onSuccess: ({ data }) => {
        let dataList = data.filter(e => e.AssetTypeId === AssetTypeIdFilter);
        if (['pavement', 'traffic-safety'].includes(pageCondition)) dataList = !!data.length ? [{ id: 0, name: 'All Road', NetworkId: selectedNetwork }, ...data] : data;
        setAsset(dataList);
        setFilterAsset(dataList);
        setIsLoading(false);
        setIsLoadingFilter(false);
        set_selected_project(0);
      },
      onFail: (err) => toast('error', err),
    });
  };

  const onScrollEnds = () => {
    // todo
    // const query = {};
    // const endpoint = endpoints.getAssetsPaginated();
    // query.page = page;
    // query.perpage = 30;
    // if (keyword.length) query.keyword = keyword;
    // if (selectedNetwork.length) query.filterByNetworkId = selectedNetwork.join(',');
    // if (selectedSection.length) query.filterBySectionId = selectedSection.join(',');
    // if (selectedRegion.length) query.filterByRegionId = selectedRegion.join(',');
    // call api paginate
  };

  useEffect(() => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setNetworks(data.Network.map(m => ({ label: m.label.toUpperCase(), value: m.id, id: m.id })));
        setRegions(data.Region.map(m => ({ label: m.name, value: m.id })));
        setSections(data.Section.map(m => ({ label: m.name, value: m.id })));
        setRankings(data.Ranking.map(m => ({ label: m.name, value: m.id })));
        setSlopeCondition(data.SlopeCondition.map(m => ({ label: m.name, value: m.id, ...m })));
        setBridgeType(data.BridgeType.map(m => ({ label: m.name, value: String(m.id) })));
        setDetectionType(data.DetectionType.map(d => ({ selector: d.id, ...d })));
      },
      onFail: (err) => toast('error', err),
    });
  }, []);

  useEffect(() => {
    setIsLoadingFilter(true);
    refresh();
  }, [page]);

  useEffect(() => {
    if (!pageCondition) setTitle('Slope');
    setTitle(capitalizeAllWords(pageCondition.split('-').join(' ')));
    setIsLoading(true);
    setSelectedNetwork(1);
    setKeyword('');
    refresh();
  }, [pageCondition]);

  useEffect(() => {
    let filter = [];
    if (pageCondition === 'pavement') {
      filter = tab === 0 ? [52, 54, 76, 77, 78] : [64, 66, 79, 80, 81];
      setEmptySet(tab === 0 ? pavementPatrolEmpty : pavementDroneEmpty);
    } else if (pageCondition === 'traffic-safety') {
      filter = [52, 55, 56, 57, 58, 59, 60, 61, 62, 63];
      setEmptySet(trafficEmpty);
    }
    setFilteredDetectionTypes(filter);
  }, [pageCondition, tab]);

  const searchKeyword = (array) => {
    if (!keyword) return setFilterAsset(array);
    const result = array?.filter((data) => {
      return data.name?.toLowerCase().search(keyword.toLowerCase()) != -1;
    });
    setFilterAsset(result);
  };

  useEffect(() => {
    const hasFilterType = (Object.keys(filterType).map(m => filterType[m].length)).filter(f => !!f);
    const selectedFilteredType = [];
    Object.keys(filterType).forEach(m => {
      if (filterType[m].length) selectedFilteredType.push({ [m]: filterType[m] });
    });
    const filteredTypeObj = Object.assign({}, ...selectedFilteredType);
    if (!hasFilterType.length) {
      searchKeyword(asset);
    } else {
      const result = asset.filter(e => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in filteredTypeObj) {
          if (e[key] === undefined || !filteredTypeObj[key].includes(e[key])) return false;
        }
        return true;
      });
      searchKeyword(_.uniq(result.flat()));
    }
    setIsLoadingFilter(true);
    setPage(1);
    refresh();
  }, [keyword, selectedNetwork, selectedRegion, selectedSection, selectedRanking, selectedBridgeType, tab]);

  useEffect(() => {
    if (!asset.length) return;
    if (!asset[selected_project]) return;
    setProject(asset[selected_project]);
  }, [asset, selected_project]);

  useEffect(() => {
    setSlopeRating(prevVal => prevVal.map(ann => ({ ...ann, y: 0 })));
    setOverallSlope(prevVal => prevVal.map(ann => ({ ...ann, y: 0 })));
    if (!project.id) return;
    const inspectionList = project.aerial_inspections_details;
    if (!inspectionList.length) return;
    inspectionList.forEach(element => {
      const switchValue = { // need to reverse the id to make incresing order
        1: 5,
        2: 4,
        3: 3,
        4: 2,
        5: 1,
      }[element.eng_slope_condition];
      setOverallSlope(prevVal => prevVal.map(ann => (ann.x === switchValue ? ({ ...ann, y: ann.y + 1 }) : ann)));
    });
    inspectionList.forEach(element => {
      if (!element.InspectionFiles.length) return;
      element.InspectionFiles.forEach(element2 => {
        if (!element2.Annotations.length) return;
        element2.Annotations.forEach(element3 => {
          setSlopeRating(prevVal => prevVal.map(ann => (ann.x === element3.SeverityId ? ({ ...ann, y: ann.y + 1 }) : ann)));
        });
      });
    });
  }, [project]);

  const tabList = [
    { label: 'PATROL', selector: 0 },
    { label: 'DRONE', selector: 1 },
  ];

  return {
    asset,
    networks,
    sections,
    regions,
    rankings,
    bridgeType,
    detectionType,
    slopeCondition,
    openFilterDialog,
    setOpenFilterDialog,
    selectedNetwork,
    setSelectedNetwork,
    selectedSection,
    setSelectedSection,
    selectedRegion,
    setSelectedRegion,
    selectedRanking,
    setSelectedRanking,
    selectedBridgeType,
    setSelectedBridgeType,
    filterAsset,
    keyword,
    setKeyword,
    isLoading,
    selected_project,
    set_selected_project,
    project,
    slopeRating,
    overallSlope,
    pageCondition,
    title,
    setTitle,
    tabList,
    tab,
    setTab,
    dataMonth,
    dataWeek,
    dataDay,
    trafficTimeframe,
    setTrafficTimeframe,
    page,
    setPage,
    refresh,
    isLoadingFilter,
    setIsLoadingFilter,
    filteredDetectionTypes,
    emptySet,
  };
}

const pavementPatrolEmpty = [
  { x: 'Pothole', y: 0 },
  { x: 'Road Patch', y: 0 },
  { x: 'Longitudinal Crack', y: 0 },
  { x: 'Crocodile Crack', y: 0 },
  { x: 'Lateral Crack', y: 0 },
];

const pavementDroneEmpty = [
  { x: 'Pothole Aerial', y: 0 },
  { x: 'Road Patch Aerial', y: 0 },
  { x: 'Longitudinal Crack Aerial', y: 0 },
  { x: 'Crocodile Crack Aerial', y: 0 },
  { x: 'Lateral Crack Aerial', y: 0 },
];

const trafficEmpty = [
  { x: 'Pothole', y: 0 },
  { x: 'Guardrail Defect', y: 0 },
  { x: 'Signage Defect', y: 0 },
  { x: 'Water Ponding', y: 0 },
  { x: 'Lamp Malfunction', y: 0 },
  { x: 'Foreign Object', y: 0 },
  { x: 'Accident/Incident', y: 0 },
  { x: 'Stray Animal', y: 0 },
  { x: 'Billboard Defect', y: 0 },
  { x: 'Line Fading', y: 0 },
];