export const ConeIcon = ({ color = "#FEB019" }) => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.15625" y="0.0449219" width="11.3374" height="10.6024" rx="0.625541" fill={color} />
      <path d="M5.58301 1.37012C5.48272 1.37012 5.3853 1.40538 5.30623 1.4703C5.22716 1.53522 5.17098 1.62607 5.14661 1.72842L4.50056 4.44672H6.05C6.10966 4.44672 6.16688 4.47165 6.20907 4.51604C6.25125 4.56042 6.27495 4.62061 6.27495 4.68338C6.27495 4.74615 6.25125 4.80634 6.20907 4.85073C6.16688 4.89511 6.10966 4.92004 6.05 4.92004H4.38809L4.16314 5.86669H6.38742C6.44708 5.86669 6.5043 5.89162 6.54649 5.936C6.58867 5.98039 6.61237 6.04058 6.61237 6.10335C6.61237 6.16612 6.58867 6.22631 6.54649 6.27069C6.5043 6.31508 6.44708 6.34001 6.38742 6.34001H4.05066L3.76903 7.52332H2.90073C2.84107 7.52332 2.78385 7.54825 2.74167 7.59264C2.69948 7.63702 2.67578 7.69721 2.67578 7.75998C2.67578 7.82275 2.69948 7.88294 2.74167 7.92733C2.78385 7.97171 2.84107 7.99664 2.90073 7.99664H8.74938C8.80904 7.99664 8.86626 7.97171 8.90844 7.92733C8.95063 7.88294 8.97433 7.82275 8.97433 7.75998C8.97433 7.69721 8.95063 7.63702 8.90844 7.59264C8.86626 7.54825 8.80904 7.52332 8.74938 7.52332H7.88063L6.50395 1.72842C6.47954 1.62591 6.42322 1.53494 6.34397 1.47001C6.26472 1.40507 6.1671 1.36991 6.06665 1.37012H5.58301Z" fill="white" />
    </svg>
  )
}

export const BigConeIcon = ({ color = "#FEB019" }) => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.357422" y="0.429688" width="16.7388" height="15.7059" rx="0.694981" fill={color} />
      <path d="M8.37042 2.39258C8.22235 2.39258 8.07852 2.44482 7.96178 2.54098C7.84504 2.63715 7.7621 2.77173 7.72611 2.92335L6.77227 6.95008H9.0599C9.14798 6.95008 9.23246 6.98702 9.29474 7.05276C9.35703 7.11851 9.39202 7.20768 9.39202 7.30066C9.39202 7.39364 9.35703 7.48281 9.29474 7.54855C9.23246 7.6143 9.14798 7.65124 9.0599 7.65124H6.60621L6.27409 9.05354H9.55808C9.64616 9.05354 9.73064 9.09048 9.79292 9.15623C9.85521 9.22197 9.8902 9.31114 9.8902 9.40412C9.8902 9.4971 9.85521 9.58627 9.79292 9.65202C9.73064 9.71776 9.64616 9.7547 9.55808 9.7547H6.10803L5.69222 11.5076H4.41024C4.32216 11.5076 4.23768 11.5445 4.1754 11.6103C4.11312 11.676 4.07812 11.7652 4.07812 11.8582C4.07812 11.9511 4.11312 12.0403 4.1754 12.1061C4.23768 12.1718 4.32216 12.2087 4.41024 12.2087H13.0453C13.1334 12.2087 13.2179 12.1718 13.2802 12.1061C13.3424 12.0403 13.3774 11.9511 13.3774 11.8582C13.3774 11.7652 13.3424 11.676 13.2802 11.6103C13.2179 11.5445 13.1334 11.5076 13.0453 11.5076H11.7627L9.73011 2.92335C9.69408 2.7715 9.61093 2.63674 9.49393 2.54055C9.37692 2.44436 9.23278 2.39227 9.08448 2.39258H8.37042Z" fill="white" />
    </svg>

  )
}