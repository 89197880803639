import { useEffect, useRef } from 'react';
import {
  TextField, MenuItem, IconButton, InputAdornment, Tooltip,
} from '@material-ui/core';
import {
  ChevronLeft, ChevronRight, FirstPage, LastPage, Search, Close, Refresh,
} from '@material-ui/icons';
import { ButtonGroup } from 'react-bootstrap';

export default ({
  totalData = 0,
  keys = '',
  setKeys = () => null,
  keyword = '',
  setKeyword = () => null,
  page = 1,
  setPage = () => null,
  perPage = 10,
  setPerPage = () => null,
  keysList = [],
  onKeyDown = () => null,
  onCloseSearch = () => null,
  onRefresh = () => null,
  isAssetList = false,
  perPageOptions = [10, 30, 50],
  showDeleted = false,
  setShowDeleted = () => null,
  isReport = false,
}) => {
  const maxPage = Math.ceil(totalData / perPage);
  const pageList = Array.from({ length: maxPage }, (_, i) => i + 1);

  return (
    <div className={`w-100 d-flex my-3 ${isAssetList ? 'justify-content-end' : 'justify-content-between'}`}>
      {!isAssetList && (
        <div className="d-flex align-items-center" style={{ transform: 'scale(0.9) translate(-5%,0)' }}>
          <TextField
            select
            value={keys}
            onChange={(e) => setKeys(e.target.value)}
            size="small"
            variant="outlined"
            style={{ minWidth: '5.5rem' }}
          >
            {keysList.map((e) => <MenuItem key={e.key} value={e.key}>{e.label}</MenuItem>)}
          </TextField>
          <TextField
            variant="outlined"
            size="small"
            disabled={!keys}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Search"
            onKeyDown={onKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Refresh" placement="top">
            <IconButton>
              <Refresh onClick={onRefresh} />
            </IconButton>
          </Tooltip>
          {/* {!!keyword.length && <IconBtn children={<Close fontSize="small" onClick={onCloseSearch} />} />} */}
        </div>
      )}
      {totalData > perPage && (
        <ButtonGroup size="small" className="flex-standard" style={{ transform: 'scale(0.9) translate(-15%,0)' }}>
          <IconBtn disabled={page === 1} children={<FirstPage fontSize="small" />} onClick={() => setPage(1)} tooltip="Go to First Page" />
          <IconBtn disabled={page === 1} children={<ChevronLeft fontSize="small" />} onClick={() => setPage(prev => prev - 1)} tooltip="Go to Previous Page" />
          <TextField
            className="mx-2"
            select
            value={page}
            onChange={(e) => setPage(e.target.value)}
            size="small"
            variant="outlined"
          >
            {pageList.map(e => (
              <MenuItem key={e} value={e}>{`${e}`}</MenuItem>
            ))}
          </TextField>
          <IconBtn disabled={page === maxPage} children={<ChevronRight fontSize="small" onClick={() => setPage(prev => prev + 1)} />} tooltip="Go to Next Page" />
          <IconBtn disabled={page === maxPage} children={<LastPage fontSize="small" onClick={() => setPage(maxPage)} />} tooltip="Go to Last Page" />
        </ButtonGroup>
      )}
      <div className="d-flex align-items-center" style={{ transform: 'scale(0.9) translate(5%,0)' }}>
        {isReport && (
          <p
            className="d-flex align-items-center pr-4"
            style={{ fontSize: 12, color: '#0691fb', cursor: 'pointer' }}
            onClick={() => {
              setShowDeleted(prev => !prev);
              setPage(1);
            }}
          >
            {showDeleted ? (
              <><span style={{ fontSize: 20 }}>&#129168;&nbsp;</span>Back to Report</>
            ) : (
              <>Deleted History<span style={{ fontSize: 20 }}>&nbsp;&#129170;</span></>
            )}
          </p>
        )}
        <p className="mr-2 text-dark" style={{ fontSize: 12 }}>Items Per Page</p>
        <TextField
          select
          value={perPage}
          onChange={(e) => setPerPage(e.target.value)}
          size="small"
          variant="outlined"
        // disabled={!(totalData > 10)}
        >
          {perPageOptions.map((e) => <MenuItem key={e} value={e}>{e}</MenuItem>)}
        </TextField>
      </div>
    </div>
  );
};

const IconBtn = ({ disabled, onClick, children, tooltip }) => {
  return (
    <Tooltip title={tooltip} placement="top">
      <IconButton disabled={disabled} onClick={onClick} className="p-1">
        {children}
      </IconButton>
    </Tooltip>
  );
};
