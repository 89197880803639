import React from 'react'
import Dropdown from './Dropdown';
import DateRange from '@Components/DateRange';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';

const GenerateReportDialog = (h) => {
  const sources = h.source.map(s => ({ label: s.label, value: s.dropdownId }))
  const pavement = [{ label: 'Data Source', value: 0 }, ...sources.filter(s => [1,2].includes(s.value)) ]
  const trafficsafety = [{ label: 'Data Source', value: 0 }, ...sources.filter(s => [3].includes(s.value)) ] 
  const selectedSource = [...(h.tab === 'pavement' ? pavement : trafficsafety)].findIndex(d => d.value == h.generateReportSource);
  return (
    <Dialog
      open={h.openGenerateReportDialog}
      onClose={() => h.setOpenGenerateReportDialog(pv => !pv)}
      PaperProps={{
        style: {
          background: 'linear-gradient(90.17deg, #32A9D1 1.24%, #4CD2AE 99.85%)',
          padding: '20px',
          borderRadius: '20px',
          width: h.isGenerateReportLoading && '400px'
        },
      }}
    >
      <DialogTitle>
        <h2
          style={{
            color: 'white',
            textAlign: 'center',
            fontWeight: 700,
          }}>
          Generate Report
        </h2>
      </DialogTitle>
      {h.isGenerateReportLoading ? (< CenteredLoadingContainer height="50vh" size={75} hasText text="generate report" loadingBarColor='white' textColor='white' />
      ) : (
        <>
          <DialogContent>
            {[
              {
                selected: selectedSource, setSelected: h.setGenerateReportSource,
                item: h.tab === 'pavement' ? pavement : trafficsafety
              },
              {
                selected: h.generateReportNetwork, setSelected: h.setGenerateReportNetwork,
                item: [{ label: 'Network', value: 0 }, ...h.networks.map(x => ({ label: x.label, value: x.value }))]
              },
              {
                selected: h.generateReportSection, setSelected: h.setGenerateReportSection,
                item: [{ label: 'Section', value: 0 }, ...h.sections.map(x => ({ label: x.label, value: x.value }))]
              },
            ].map((x, index) => (
              <div key={index} style={{ marginBottom: '12px' }} >
                <Dropdown
                  selectedItem={x.selected}
                  setSelectedItem={x.setSelected}
                  itemList={x.item}
                  tab={h.tab}
                  width="400px"
                  propertyValue="value"
                  Hx="h6"
                  size="small"
                />
              </div>
            ))}
            <div style={{ marginTop: '12px' }}>
              <DateRange
                reportDate
                dateColor='white'
                marginBottom='10px'
                textColor='black'
                borderRadius='20px'
                width="400px"
                fontSize='12px'
                paddingTop='9px'
                paddingBottom='8px'
                paddingLeft='15px'
                paddingRight='15px'
                icon={<ExpandMore />}
                boxShadow='0px 1px 1px rgba(0, 0, 0, 0.2)'
                isDateEditing={h.generateReportDateSelectedActive}
                setDateEditing={h.setGenerateReportDateSelectedActive}
                selectionRange={h.generateReportDateSelected}
                setSelectionRange={h.setGenerateReportDateSelected} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => h.setOpenGenerateReportDialog(pv => !pv)}
              style={{
                backgroundColor: '#C9C8C8',
                color: 'white',
                borderRadius: '20px',
                fontSize: 11,
                padding: 8,
                fontWeight: 700
              }}>
              Close
            </Button>
            <Button onClick={() => h.generateReport()} autoFocus
              style={{
                backgroundColor: '#0061AA',
                color: 'white',
                borderRadius: '20px',
                fontSize: 11,
                padding: 8,
                fontWeight: 700
              }}>
              Submit
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog >
  )
}

export default GenerateReportDialog