/* eslint-disable complexity */
import React from 'react';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
} from 'victory';
import { MoneyFormat } from '@Helpers';
import _ from 'lodash';

let colorfills = ['#7ad5b0', '#468ef7', '#f0b334', '#d4371b', '#8d95aa', '#B2DEFF'];
function BarChart(props) {

  const data = props.data ?? [];
  colorfills = props.colorfills ?? colorfills;
  const formatData = (data) => {
    return data.map(obj =>
      Object.entries(obj).map(([key, value]) => ({ label: key, result: value }))
    ).flat();
  };

  let defaultData
  switch (props.type) {
    case 'network':
      defaultData = props.networks
      break;
    case 'region':
      defaultData = props.regions
      break;
    case 'section':
      defaultData = props.sections
      break;
    default:
      defaultData = formatData(props.data) || []
  }

  const incomingData = formatData(data)
  const filterData = defaultData?.map((defaultItem) => {
    const matchingItem = incomingData.find((x) => x.label === defaultItem.label); // Check if there's a matching label in formatData
    return matchingItem ? { ...defaultItem, result: matchingItem.result } : defaultItem;
  });

  const colors = (selector) => {
    //NSE
    if (['N1', 'N2', 'N3', 'N4', 'N5', 'C1', 'C2', 'C3', 'C4H', 'C4\nNKVE', 'C5', 'S1', 'S2', 'S3', 'S4', 'S5[K]', 'S5C', 'S5[D]'].includes(selector)) {
      return '#AEC9FE';
      //SPDH
    } else if (selector === 'S1\nSPDH') {
      return '#F6A6FF';
      //NSECL
    } else if (['C6[E]', 'C6[P]', 'C6[A]'].includes(selector)) {
      return '#85E3FF';
      //MSSC
    } else if (['S6', 'S6[J]'].includes(selector)) {
      return '#FFABAB';
      //PB
    } else if (selector === 'N6') {
      return '#BDFCC3';
      //BKE
    } else if (selector === 'N7') {
      return '#E7FFA9';
      //LPT2
    } else if (selector === 'LPT') {
      return '#30A6D3';
      //NA
    } else if (selector === 'N/A') {
      return 'green';
      //Date
    } else if (/^\d{1,2}\/\d{1,2}$/.test(selector)) {
      return '#30A6D3';
    };
    return '#5B6272';
  }

  function formatDate(dateStr) {
    const [year, month, day] = dateStr.split('-');
    // return `${day}-${month}-${year}`;
    return { formattedDate: `${day}/${month}`, year };
  }

  function formatSelectorName(name) {
    if (name === 'N7-BKE') {
      return 'N7\nBKE';
    } else if (name === 'C6-ELITE') {
      return 'C6\nELITE';
    } else if (name === 'S1-SPDH') {
      return 'S1\nSPDH';
    } else if (name === 'C6 [E]') {
      return 'C6[E]';
    } else if (name === 'C6 [P]') {
      return 'C6[P]';
    } else if (name === 'C6 [A]') {
      return 'C6[A]';
    } else if (name === 'C4 NKVE') {
      return 'C4\nNKVE';
    } else if (name === 'S5 [D]') {
      return 'S5[D]';
    } else if (name === 'S5 [K]') {
      return 'S5[K]';
    } else if (name === 'OUT OF BOUND') {
      return 'N/A';
    } else {
      return name;
    }
  }

  return (
    <VictoryChart
      // domainPadding will add space to each side of VictoryBar to prevent it from overlapping the axis
      domainPadding={props.domainPadding ?? { x: [200, 200], y: [50, 50] }}
      width={props.width ?? 1200}
      height={props.height ?? 500}
      padding={props.paddingChart}
      theme={VictoryTheme.material}
      style={{
        parent: { backgroundColor: 'white' },
      }}
    >

      <VictoryAxis
        // tickValues specifies both the number of ticks and where they are placed on the axis
        tickFormat={(x) => x}
        style={{
          tickLabels: props.tickLabels ?? '',
          ticks: { size: 0 },
          axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
          grid: { stroke: 'transparent' },
          tickLabels: {
            fontSize: props.sizeLabelX ?? 10, fontFamily: 'CeraProRegular', angle: props.angle || 0, transform: `translate(0, 5)`,
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickFormat={(x) => (x < 0.1 ? '' : MoneyFormat(x))}
        axisLabelComponent={<VictoryLabel angle={0} verticalAnchor="start" dy={-150} dx={0} />}
        style={{
          axis: { stroke: '#FFFFFF' },
          tickLabels: {
            fontFamily: 'CeraProRegular',
            fontSize: 14,
            padding: 5,
            fill: 'rgba(30, 52, 101, 0.65)',
          },
          ticks: { stroke: '#FFFFFF', size: 0 },
          grid: { stroke: 'rgba(30, 52, 101, 0.15)', strokeDasharray: false },
        }}
      />
      {props.data && (
        <VictoryBar
          data={filterData?.map((data) => {
            const { formattedDate, year } = /^\d{4}-\d{2}-\d{2}$/.test(data.label)
              ? formatDate(data.label)
              : { formattedDate: formatSelectorName(data.label?.toUpperCase()), year: null };
            return {
              x: formattedDate,
              y: data?.result ?? 0,
              year,
            };
          }) || []}
          x="x"
          cornerRadius={3}
          barWidth={props.barWidth || 30}
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 },
          }}
          style={{
            data: {
              fill: ({ datum }) => props.allowBarColor ? colors(datum.x) : 'rgb(91,98,114)',
            },
          }}
          labelComponent={<VictoryTooltip />}
          labels={({ datum }) => {
            return datum._y;
          }}
          events={[{ target: "data", eventHandlers: { onClick: props.onClick } }]}
        />
      )}
      {props.legendStyle && <VictoryLegend
        x={15}
        y={1}
        orientation="horizontal"
        gutter={20}
        style={{ labels: { fontFamily: 'CeraProRegular', fontSize: 8 } }}
        data={props.legendStyle?.map((l, lidx) => ({ name: l.label, symbol: { fill: l.color, type: 'square' } }))}
        {...props.legendStyles}
      />}

      {/* {props.data && [2].includes(props.tab) && (
        <VictoryGroup offset={20} colorScale={colorfills}>
          {props.legend.map(k => (
            <VictoryBar
              data={props.data || []}
              x="x"
              y={k.selector}
              barWidth={20}
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
              cornerRadius={5}
              labels={({ datum }) => {
                return datum[k.selector];
              }}
              labelComponent={<VictoryTooltip />}
            />
          ))}
        </VictoryGroup>
      )} */}
      {props.topLabel && (
        <VictoryLabel
          // textAnchor='middle'
          style={{ fontSize: props.fontSize ?? 30, fill: '#1F3566', fontWeight: 600 }}
          x={props.labelX ?? 0}
          y={props.labelY ?? 60}
          text={props.topLabel}
          {...props.topLabelStyles}
        />
      )}
    </VictoryChart>
  );
}

export default BarChart;
