import Map from '@Components/MapV2';
import { Box, Grid, MenuItem, TextField, makeStyles } from '@material-ui/core';
import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import BarChart from '@Components/BarChart/v5';
import GradientTabs from '@Components/GradientTabs';
import GroupBarChart from '@Components/BarChart/GroupBarChart';
import moment from 'moment';
import useHook from './hook';
import AssetTable from './AssetTable';
import GroupBar from './GroupBar';

const titleStyle = {
  fontWeight: 600,
  fontSize: 28,
  color: 'var(--dark-blue-color)',
};

export default function Dashboard() {
  const h = useHook();
  const classes = useStyles();
  const isWeekly = ['weekly'].includes(h.trafficTimeframe);
  const DashboardGraphCard = ({ gridSize, title, component, gridStyle, paperStyle, hasTimeSelector }) => {
    return (
      <Grid item xs={gridSize.xs} md={gridSize.md ?? gridSize.xs} style={{ height: '30vh', paddingTop: 15, ...gridStyle }}>
        <div className="paper-shadow bg-white h-100" style={{ ...paperStyle }}>
          <div className="p-3 d-flex justify-content-between align-items-center" style={{ backgroundColor: '#51DCA5' }}>
            <h4 style={{ color: 'white', fontSize: 16 }}>{title}</h4>
            {hasTimeSelector && (
              <TextField
                select
                size="small"
                value={h.trafficTimeframe}
                onChange={(e) => h.setTrafficTimeframe(e.target.value)}
                className={classes.root}
              >
                <MenuItem value={'weekly'}>Weekly</MenuItem>
                <MenuItem value={'monthly'}>Monthly</MenuItem>
              </TextField>
            )}
          </div>
          {(_.isEmpty(h.project) || (h.project?.id !== 0 && !h.project?.aerial_inspections_details?.length) || !h.filterAsset.length) ? (
            <div className="w-100 h-75 flex-standard">
              <p className='text-body'>{['slope', 'bridge'].includes(h.pageCondition) ? 'No Inspection Record' : 'No Finding'}</p>
            </div>
          ) : component}
        </div>
      </Grid>
    );
  };

  return (
    !!h.isLoading ? (
      <CenteredLoadingContainer height="70vh" size={75} hasText text="dashboard" />
    ) : (
      <>
        <Box display="flex" justifyContent="center" alignItems="center" paddingX={3}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <h1 className="text-title" style={{ marginTop: 35, paddingLeft: 8 }}>
                {`Dashboard - ${h.title}`}
              </h1>
            </Grid>
            {['pavement'].includes(h.pageCondition) && (
              <Grid xs={12} item>
                <GradientTabs tabs={h.tabList} tab={h.tab} setTab={h.setTab} />
              </Grid>
            )}
            <Grid container item direction="row" xs={9} alignItems="stretch">
              <Grid item xs={12} style={{ height: '50vh' }}>
                <div className="paper-shadow bg-white h-100 dashboard" style={{ borderTopLeftRadius: '20px' }}>
                  <Map mapStyle={{ width: '100%', height: '100%' }} filtered_projects={h.asset} {...h} />
                </div>
              </Grid>
              {[
                {
                  gridSize: { xs: 3, md: 3 },
                  title: h.title === 'Slope' ? 'Defect Rating' : `${h.title} Condition (AI)`,
                  page: ['slope', 'bridge'],
                  gridStyle: { paddingRight: 5 },
                  paperStyle: { borderBottomLeftRadius: '20px' },
                  component: <BarChart label={{ x: 'Count', y: 'Rating' }} category={{ x: ['1', '2', '3', '4', '5'] }} tableData={h.slopeRating} />,
                },
                {
                  gridSize: { xs: 3, md: 3 },
                  title: `${h.title} Condition Rating`,
                  page: ['slope', 'bridge'],
                  gridStyle: { paddingInline: 5 },
                  component: (
                    <BarChart
                      label={{ x: 'Count', y: 'Slope Rank' }}
                      category={{ x: ['Very Poor', 'Poor', 'Average', 'Good', 'Very Good'] }}
                      tableData={h.overallSlope}
                    />
                  ),
                },
                {
                  gridSize: { xs: 6, md: 6 },
                  title: `${h.title} Condition (Graph)`,
                  page: ['slope', 'bridge'],
                  gridStyle: { paddingLeft: 5 },
                  component: <GroupBar chartData={h.project} slopeCondition={h.slopeCondition} />,
                },
                {
                  gridSize: { xs: ['traffic-safety'].includes(h.pageCondition) ? 6 : 3 },
                  title: 'Daily Detection Overview',
                  page: ['pavement', 'traffic-safety'],
                  gridStyle: { paddingRight: 5 },
                  paperStyle: { borderBottomLeftRadius: '20px' },
                  component: <GroupBarChart height={400} width={['pavement'].includes(h.pageCondition) ? 400 : 900} offset={['pavement'].includes(h.pageCondition) ? 13 : 11} data={h.dataDay.map(e => ({ ...e, data: h.emptySet.map(f => e.data.find(z => z.x === f.x) || f) }))} />,
                },
                {
                  gridSize: { xs: 4 },
                  title: 'Weekly Detection Overview',
                  page: ['pavement'],
                  gridStyle: { paddingInline: 5 },
                  component: <GroupBarChart height={400} width={600} offset={9} data={h.dataWeek} withDate />,
                },
                {
                  gridSize: { xs: 5 },
                  title: 'Monthly Detection Overview',
                  page: ['pavement'],
                  gridStyle: { paddingLeft: 5 },
                  component: <GroupBarChart height={400} width={800} offset={7} data={h.dataMonth} legendX={200} hasLegend />,
                },
                {
                  gridSize: { xs: 6 },
                  title: `${isWeekly ? 'Weekly' : 'Monthly'} Detection Overview`,
                  page: ['traffic-safety'],
                  gridStyle: { paddingLeft: 5 },
                  component: <GroupBarChart height={400} width={900} offset={isWeekly ? 13 : 8} data={isWeekly ? h.dataWeek : h.dataMonth} legendX={300} hasLegend withDate={isWeekly} />,
                  hasTimeSelector: true,
                },
              ].filter(({ page }) => page.includes(h.pageCondition)).map(e => <DashboardGraphCard {...e} />)}
            </Grid>
            <AssetTable {...h} />
          </Grid>
        </Box>
      </>
    )
  );
}

const dataMonth = [
  {
    type: 'North Bound',
    data: [
      { x: moment().startOf('year').add(0, 'months').format('MMM'), y: 1 },
      { x: moment().startOf('year').add(1, 'months').format('MMM'), y: 2 },
      { x: moment().startOf('year').add(2, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(3, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(4, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(5, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(6, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(7, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(8, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(9, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(10, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(11, 'months').format('MMM'), y: 3 },
    ],
  },
  {
    type: 'South Bound',
    data: [
      { x: moment().startOf('year').add(0, 'months').format('MMM'), y: 2 },
      { x: moment().startOf('year').add(1, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(2, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(3, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(4, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(5, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(6, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(7, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(8, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(9, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(10, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(11, 'months').format('MMM'), y: 4 },
    ],
  },
];
const dataWeek = [
  {
    type: 'North Bound',
    data: [
      { x: moment().startOf('isoWeek').add(0, 'days').format('ddd-(D/M)'), y: 1 },
      { x: moment().startOf('isoWeek').add(1, 'days').format('ddd-(D/M)'), y: 2 },
      { x: moment().startOf('isoWeek').add(2, 'days').format('ddd-(D/M)'), y: 3 },
      { x: moment().startOf('isoWeek').add(3, 'days').format('ddd-(D/M)'), y: 3 },
      { x: moment().startOf('isoWeek').add(4, 'days').format('ddd-(D/M)'), y: 3 },
      { x: moment().startOf('isoWeek').add(5, 'days').format('ddd-(D/M)'), y: 3 },
      { x: moment().startOf('isoWeek').add(6, 'days').format('ddd-(D/M)'), y: 3 },
    ],
  },
  {
    type: 'South Bound',
    data: [
      { x: moment().startOf('isoWeek').add(0, 'days').format('ddd-(D/M)'), y: 2 },
      { x: moment().startOf('isoWeek').add(1, 'days').format('ddd-(D/M)'), y: 3 },
      { x: moment().startOf('isoWeek').add(2, 'days').format('ddd-(D/M)'), y: 4 },
      { x: moment().startOf('isoWeek').add(3, 'days').format('ddd-(D/M)'), y: 4 },
      { x: moment().startOf('isoWeek').add(4, 'days').format('ddd-(D/M)'), y: 4 },
      { x: moment().startOf('isoWeek').add(5, 'days').format('ddd-(D/M)'), y: 4 },
      { x: moment().startOf('isoWeek').add(6, 'days').format('ddd-(D/M)'), y: 4 },
    ],
  },
];
const dataDaily = [
  {
    type: 'North Bound',
    data: [
      { x: 'Pothole', y: 1 },
      { x: 'Surface Crack', y: 2 },
      { x: 'Road Patch', y: 3 },
    ],
  },
  {
    type: 'South Bound',
    data: [
      { x: 'Pothole', y: 2 },
      { x: 'Surface Crack', y: 3 },
      { x: 'Road Patch', y: 4 },
    ],
  },
];

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      color: 'white',
      backgroundColor: 'transparent',
      fontSize: 16,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiSelect-select': {
      padding: '0px 24px 0px 0px',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiSelect-icon': {
      color: 'white',
    },
  },
}));