import React from 'react';
import LineVisibilityIcon from '@Assets/Icons/FilterVectorGrey.svg';
import { Box, Button, Checkbox, Divider, Grid, IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const LineVisibility = (props) => {
  return (
    <div className='position-relative'>
      <Button
        style={{
          float: 'right',
          minWidth: 43,
          height: 43,
          zIndex: 999,
          position: 'relative',
          top: 295,
          backgroundColor: 'white',
          borderRadius: 0,
          right: -43,
        }}
        onClick={() => { props.onClickMenu('visibility'); props.onClickDetection(null); }}
      >
        <img style={{ width: 25, height: 25 }} src={LineVisibilityIcon} alt="LineVisibilityIcon" />
      </Button>
      {props.openVisibilityMenu && (
        <Box
          item
          xs={6}
          md={4}
          className="sidebar ml-auto"
          style={{
            zIndex: 999,
            position: 'absolute',
            top: 300,
            right: 65,
            width: '15rem',
          }}
          onMouseOver={() => props.setOnHover(true)}
          onMouseLeave={() => props.setOnHover(false)}
        >
          <div
            className="p-8"
            style={{
              // display: 'flex',
              justifyContent: 'left',
              flexWrap: 'wrap',
              backgroundColor: 'white',
              outline: '1px solid var(--primary-color)',
              borderRadius: 3,
            }}
          >
            <Grid
              container
              spacing={1}
              className="d-flex flex-column py-3 px-4"
              style={{ minWidth: '100%' }}
            >
              <Grid xs={12} className='d-flex justify-content-between align-items-center'>
                <p className='text-uppercase' style={{ fontSize: '0.9rem', color: '#0061AA', fontWeight: 'bold', }}>Show/Hide</p>
                <IconButton color="secondary" className='p-0' onClick={() => { props.setOpenVisibilityMenu(false); }}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              style={{ padding: 0 }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              className='hide-scroll'
            >
              {[
                {
                  title: 'Line View', checked: props.toggleLineLayer, onChange: props.setToggleLineLayer,
                },
                {
                  title: 'Marker Icon', checked: props.toggleMarkerIcon, onChange: props.setToggleMarkerIcon,
                }
              ].map(e => (
                <ListItem className='py-0'>
                  <Checkbox checked={e.checked} onChange={(a) => e.onChange(a.target.checked)} />
                  <ListItemText
                    primary={e.title}
                    style={{
                      color: true ? '#51DBA5' : '#384768',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Box>
      )}
    </div>
  )
}

export default LineVisibility;
