import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, MenuItem, FormControl, Select, } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
const _ = require('lodash');

const useStyles = makeStyles(() => ({
  formControlContainer: {
    height: '0',
  },
  formControl: {
    width: '100%',
    visibility: 'hidden',
    padding: 0,
  },
}));

const StyledMenuItem = withStyles(() => ({
  root: {
    justifyContent: 'center',
    backgroundColor: 'var(--dark-blue-color)',
    '& h1, h2, h3, h4, h5, h6': {
      textAlign: 'center',
      color: 'white',
    },
    '&:focus, &:hover': {
      backgroundColor: 'white',
      '& h1, h2, h3, h4, h5, h6': {
        color: 'var(--dark-blue-color)',
      },
    },
  },
}))(MenuItem);

export default function Dropdown({
  selectedItem = 0, setSelectedItem, itemList, width, Hx = 'h3', size, tab
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setSelectedItem(event.target.value);
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        endIcon={<ExpandMore style={{ color: 'var(--dark-blue-color)' }} />}
        variant='contained'
        style={{
          width: width || '100%',
          height: (size === 'small') ? '40px' : '55px',
          borderRadius: 10,
          backgroundColor: 'white',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          lineHeight: 'normal',
          borderRadius: '20px'
        }}
      >
        <div>
          <Hx style={{ color: 'var(--dark-blue-color)', fontSize: '12px', fontWeight: 400 }}>
            {tab === 'pavement' ? (
              _.isEqual([64, 65, 66], selectedItem) ? 'Drone' :
                _.isEqual([52, 53, 54], selectedItem) ? 'Patrol' :
                  itemList[selectedItem]?.label)
              :
              (_.isEqual([52, 55, 56, 57, 58, 59, 60, 61, 62, 63], selectedItem) ? 'Patrol' : itemList[selectedItem]?.label)
            }
          </Hx>
        </div>
      </Button>
      <div className={classes.formControlContainer}>
        <FormControl className={classes.formControl}>
          <Select
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onChange={handleChange}
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: '10px',
                },
              },
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              getContentAnchorEl: null,
              MenuListProps: { disablePadding: true },
            }}
          >
            {itemList?.map((x) => (
              <StyledMenuItem
                // selected={selectedItem}
                value={x.value}
                dense
              >
                <Hx>{x.label}</Hx>
              </StyledMenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
