import React from 'react';
import {
  VictoryChart, VictoryAxis, VictoryBar, VictoryLabel, VictoryLegend, VictoryTooltip, VictoryTheme, VictoryStack,
} from 'victory';
import NoData from '@Assets/Images/Data-not-found3.png';

const StackedBar = (props) => {
  const colorfills = props.colorfills ?? ['#468ef7', '#7ad5b0', '#ffae42', '#fff380', 'pink']; // Blue for NorthBound, Green for SouthBound
  const data = props.data ?? [];
  const formatData = (data) => {
    return data.map(obj => ({ id: obj.label }))
  };

  const defaultData = props.isCountByNetwork ? formatData(props.networks) : formatData(props.regions)

  const filterData = defaultData?.map((defaultItem) => {
    const matchingItem = data.find((x) => x.id === defaultItem.id); // Check if there's a matching label in formatData
    return matchingItem ? { ...defaultItem, NorthBound: matchingItem.NorthBound, SouthBound: matchingItem.SouthBound, WestBound: matchingItem.WestBound, EastBound: matchingItem.EastBound, NA: matchingItem.NA } : defaultItem;
  });

  const isDataEmpty = filterData.every(d => (d.NorthBound ?? 0) === 0 && (d.SouthBound ?? 0) === 0 && (d.WestBound ?? 0) === 0 && (d.EastBound ?? 0) === 0 && (d.NA ?? 0) === 0);
  return (
    <>
      {!isDataEmpty ? (
        <VictoryChart
          domainPadding={{ x: [50, 50], y: [125, 125] }}
          width={props.width ?? 1200}
          height={props.height ?? 500}
          padding={{ top: 50, bottom: 60, left: 50, right: 50 }}
          theme={VictoryTheme.material}
          style={{
            parent: { backgroundColor: 'white' },
          }}
        >
          <VictoryLegend
            x={650}
            y={0}
            orientation="horizontal"
            gutter={20}
            style={{ labels: { fontFamily: 'CeraProRegular', fontSize: 12 } }}
            data={[
              { name: 'NorthBound', symbol: { fill: colorfills[0], type: 'square' } },
              { name: 'SouthBound', symbol: { fill: colorfills[1], type: 'square' } },
              { name: 'WestBound', symbol: { fill: colorfills[2], type: 'square' } },
              { name: 'EastBound', symbol: { fill: colorfills[3], type: 'square' } },
              { name: 'Not Available', symbol: { fill: colorfills[4], type: 'square' } },
            ]}
          />

          <VictoryAxis
            tickFormat={(x) => x}
            style={{
              tickLabels: { fontFamily: 'CeraProRegular', fontSize: 20, padding: 15 },
              ticks: { size: 0 },
              axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
              grid: { stroke: 'transparent' },
            }}
          />

          <VictoryAxis
            dependentAxis
            tickFormat={(x) => x}
            axisLabelComponent={<VictoryLabel angle={0} verticalAnchor="start" dy={-150} dx={0} />}
            style={{
              axis: { stroke: '#FFFFFF' },
              tickLabels: {
                fontFamily: 'CeraProRegular',
                fontSize: 14,
                padding: 5,
                fill: 'rgba(30, 52, 101, 0.65)',
              },
              ticks: { stroke: '#FFFFFF', size: 0 },
              grid: { stroke: 'rgba(30, 52, 101, 0.15)', strokeDasharray: false },
            }}
          />

          <VictoryStack offset={0} colorScale={colorfills}>
            {['NorthBound', 'SouthBound', 'WestBound', 'EastBound', 'NA'].map((direction) => (
              <VictoryBar
                key={direction}
                data={filterData.map(d => ({ x: d.id?.toUpperCase(), y: d[direction] ?? 0 }))}
                barWidth={30}
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 },
                }}
                cornerRadius={3}
                labels={({ datum }) => datum.y}
                labelComponent={<VictoryTooltip />}
                events={[{ target: "data", eventHandlers: { onClick: props.onClick } }]}
              />
            ))}
          </VictoryStack>


          {props.topLabel && (
            <VictoryLabel
              style={{ fontSize: 30, fill: '#1F3566', fontWeight: 600 }}
              x={50}
              y={60}
              text={props.topLabel}
              {...props.topLabelStyles}
            />
          )}
        </VictoryChart>
      )
        : (
          <div className="d-flex justify-content-center nodata-chart">
            <img src={NoData} alt="No Data" />
          </div>
        )
      }
    </>
  );
};

export default StackedBar;
