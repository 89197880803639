import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import Button from '@Components/Button';
import { addDay } from '@Helpers';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useState } from 'react';

export default function DateRange({
  selectionRange, setSelectionRange, isDateEditing, setDateEditing, textColor, borderRadius, width,
  fontSize, paddingTop, paddingBottom, paddingLeft, paddingRight, icon, dateColor, marginBottom,
  reportDate, boxShadow
}) {
  const [tempRange, setTempRange] = useState(selectionRange);
  const [initialDate, setInitialDate] = useState(false);

  const handleSelect = ({ selection }) => {
    setTempRange(selection);
  };

  const handleDialogClose = () => {
    setDateEditing(false);
  };

  const handleDialogOpen = () => {
    setTempRange(selectionRange);
    setDateEditing(true);
  };

  const handleConfirm = () => {
    const { endDate } = tempRange;
    endDate.setHours(23, 59, 59);
    setSelectionRange({ ...tempRange, endDate });
    setDateEditing(false);
  };

  const formatDateRange = () => {
    if (initialDate && reportDate) {
      return `${new Date(Date.UTC(selectionRange.startDate.getFullYear(), selectionRange.startDate.getMonth(), selectionRange.startDate.getDate())).toLocaleDateString('en-GB')} - ${new Date(Date.UTC(selectionRange.endDate.getFullYear(), selectionRange.endDate.getMonth(), selectionRange.endDate.getDate())).toLocaleDateString('en-GB')}`;
    }
    return 'Date';
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {!reportDate && <Box mr={2} style={{ fontFamily: 'CeraProRegular', color: dateColor, marginBottom: marginBottom }}>
          {`${new Date(Date.UTC(selectionRange.startDate.getFullYear(), selectionRange.startDate.getMonth(), selectionRange.startDate.getDate())).toLocaleDateString('en-GB')} - ${new Date(Date.UTC(selectionRange.endDate.getFullYear(), selectionRange.endDate.getMonth(), selectionRange.endDate.getDate())).toLocaleDateString('en-GB')}`}
        </Box>}
        <Button
          onClick={() => {
            handleDialogOpen()
            setInitialDate(() => true)
          }}
          endIcon={icon}
          style={{
            color: textColor || '#30A6D3',
            backgroundColor: 'white',
            border: '1px solid #ADB5BD',
            paddingTop: paddingTop || 4,
            paddingBottom: paddingBottom || 4,
            paddingLeft,
            paddingRight,
            borderRadius,
            width,
            fontSize,
            boxShadow,
            justifyContent: icon && 'space-between',
          }}>
          {formatDateRange()}
        </Button>
      </Box >
      <Dialog open={isDateEditing} onClose={handleDialogClose} maxWidth="md">
        <DialogTitle>Choose Date Range</DialogTitle>
        <DialogContent>
          <DateRangePicker
            ranges={[{ ...tempRange, key: 'selection', color: 'var(--primary-color)' }]}
            onChange={handleSelect}
            maxDate={new Date()}
            staticRanges={[]}
            inputRanges={[]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} style={{ height: 'max-content', backgroundColor: 'white', color: 'black' }}>Close</Button>
          <Button onClick={handleConfirm} style={{ height: 'max-content' }}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
