import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { IconButton, TableCell, TableFooter, TableRow } from '@material-ui/core';
import { checkMediumMediaQuery } from '@Helpers';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
});

export default ({
  tableData, selected_project, set_selected_project, networks, pageCondition, page, setPage,
}) => {
  const classes = useStyles();
  const isMediumMedia = checkMediumMediaQuery();

  const columns = [
    {
      name: ['slope', 'bridge'].includes(pageCondition) ? 'Asset ID' : 'Road Name',
      selector: 'name',
    },
    {
      name: 'Network',
      selector: 'network',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
  ];

  const options = {
    filter: 'false',
    print: 'false',
    download: 'false',
    sort: 'false',
    viewColumns: 'false',
    search: 'false',
    tableBodyHeight: isMediumMedia ? '58.5vh' : '54vh',
    selectableRows: 'single',
    selectableRowsOnClick: true,
    onRowsDelete: () => false,
    rowsPerPage: tableData.length,
    rowsPerPageOptions: [tableData.length],
    customToolbarSelect: () => <div />,
    rowsSelected: [selected_project],
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      set_selected_project(rowsSelected[0])
    },
    customFooter: (count) => (
      <div className='d-flex justify-content-end align-items-center' style={{ borderRadius: 20 }}>
        <p style={{ fontSize: 15, color: 'grey', marginRight: 5 }}>Page: {page}</p>
        <IconButton onClick={() => setPage(pv => pv - 1)} disabled={page === 1}>
          <ChevronLeft />
        </IconButton>
        <IconButton onClick={() => setPage(pv => pv + 1)} disabled={count < 30}>
          <ChevronRight />
        </IconButton>
      </div>
    ),
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        options={options}
        columns={columns.map((col) => ({ ...col, label: col.name, name: col.selector }))}
        data={tableData.map((x) => {
          return ({
            ...x,
            name: x.name,
            network: networks.find(e => e.value === x.NetworkId)?.label ?? 'No Record',
          });
        })}
      />
    </MuiThemeProvider>
  );
};

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
  overrides: {
    MuiToolbar: { root: { display: 'none' } },
    MUIDataTableSelectCell: {
      root: {
        display: 'none',
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        display: 'none',
      },
    },
    MuiTableHead: {
      root: {
        '& .MuiTableCell-root': {
          color: '#000000DE',
          fontSize: 18,
          fontWeight: 500,
        },
      },
    },
    MUIDataTableHeadCell: {
      toolButton: {
        justifyContent: 'center',
      },
    },
    MuiTableRow: {
      root: {
        color: '#048279',
      },
    },
    MuiTableCell: {
      body: {
        color: '#001847',
        fontSize: 14,
        fontWeight: 400,
      },
      root: {
        paddingLeft: '20px !important',
      }
    },
  },
});
