import React, { useRef } from 'react';
import { IconButton, Grid } from '@material-ui/core';
import Button from '@Components/Button';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const FilterButtonList = ({ items = [], selectedItems = "", onClickItem = () => null, primaryColor, marginLeft = 0 }) => {
  const listRef = useRef(null);

  const scroll = (scrollOffset) => {
    if (listRef.current) {
      listRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <div className="d-flex align-items-center" style={{ overflowX: 'hidden' }}>
      {/* <IconButton
        disableRipple
        style={{ width: 30, height: 30, backgroundColor: '#fcfcfc' }}
        onClick={() => scroll(-40)}
      >
        <ChevronLeft style={{ color: primaryColor }} />
      </IconButton> */}
      <div
        ref={listRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'auto',
          marginLeft: marginLeft,
          marginRight: 0,
          whiteSpace: 'nowrap',
          flex: 1,
          scrollbarWidth: 'none',
        }}
        className="hide-scrollbar"
      >
        {items.map((item) => {
          console.log('inside item', item);
          return (
            <Grid item key={item.id} style={{ padding: '0 5px' }}>
              <Button
                onClick={() => onClickItem(item.id)}
                style={{
                  color: selectedItems.includes(item.id) ? 'white' : '#30A6D3',
                  background: selectedItems.includes(item.id) ? primaryColor : 'white',
                  borderRadius: '20px',
                  padding: '4px 5px',
                  minWidth: 'auto',
                  fontSize: 9
                }}
              >
                {item.label}
              </Button>
            </Grid>
          );
        })}
      </div>
      {/* <IconButton
        disableRipple
        style={{
          width: 30, height: 30, backgroundColor: '#fcfcfc', marginRight: 10,
        }}
        onClick={() => scroll(40)}
      >
        <ChevronRight style={{ color: primaryColor }} />
      </IconButton> */}
      <style jsx>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </div >
  );
};

export default FilterButtonList;