/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Typography, List, ListSubheader, ListItem as ListItemButton, ListItemIcon, ListItemText, Collapse, Checkbox, Divider, colors, IconButton,
} from '@material-ui/core';
import {
  Inbox as InboxIcon, Drafts as DraftsIcon, Send as SendIcon, ExpandLess, ExpandMore, StarBorder, Close, ChevronLeft,
} from '@material-ui/icons';
import Button from '@Components/Button';
import filterIcon from '@Assets/Icons/PinMap.svg';
import _ from 'lodash';
import { checkMediumMediaQuery } from '@Helpers';

export default function FilterView({ value, set_value, ...props }) {
  //   const [timeline, setTimeline] = useState(false);
  const xValue = (props.isDrawAnnotation || props.isInspection) ? '0%' : '30%';
  const yValue = (props.isDrawAnnotation || props.isInspection) ? '-25px' : '-35px';
  const [openSlope, setOpenSlope] = React.useState(false);
  const [openBridge, setOpenBridge] = React.useState(false);
  const [openPavement, setOpenPaveopenPavement] = React.useState(false);
  const [openTrafficSafety, setOpenTrafficSafety] = React.useState(false);
  const [openRoadFurniture, setOpenRoadFurniture] = React.useState(false);
  const [openSubCategory, setOpenSubCategory] = React.useState(null);
  const [showAssetMarkers, setShowAssetMarkers] = React.useState(true);
  const [activeItem, setActiveItem] = useState(null);
  const isMediumMedia = checkMediumMediaQuery();
  console.log('DEBUG filter', props.detectionType);
  const filterList = [
    {
      name: 'Slope & Bridge', open: openSlope, setOpen: setOpenSlope, items: [],
    },
    {
      name: 'Pavement', open: openPavement, setOpen: setOpenPaveopenPavement, subCategory: ['Patrol', 'Drone'], items: props.detectionType.filter(dt => openSubCategory === 'Patrol' ? [52, 54, 76, 77, 78].includes(dt.id) : [64, 66, 79, 80, 81].includes(dt.id)),
    },
    {
      name: 'Traffic Safety', open: openTrafficSafety, setOpen: setOpenTrafficSafety, subCategory: ['Patrol'], items: props.detectionType.filter(dt => [52, 55, 56, 57, 58, 59, 60, 61, 62, 63].includes(dt.id)),
    },
    // {
    //   name: 'RoadFurniture', open: openRoadFurniture, setOpen: setOpenRoadFurniture, items: [],
    // },
  ];
  const openFilterMainCategory = (setOpen, name) => {
    filterList.filter(fl => fl.name != name).forEach(fl => fl.setOpen(false));
    setOpen(pv => !pv);
    if (name == 'Slope & Bridge') {
      setShowAssetMarkers(pv => !pv);
    } else {
      setShowAssetMarkers(false);
    }
  };
  const openFilterSubCategory = (category) => {
    if (category == openSubCategory) setOpenSubCategory(null);
    else setOpenSubCategory(category);
  };

  const handleClick = (setOpen, name) => {
    openFilterMainCategory(setOpen, name);
    setActiveItem(name);
  };

  useEffect(() => {
    if (props.selectedDetection) props.setOpenFilterMenu(false);
  }, [props.selectedDetection]);
  useEffect(() => {
    if (showAssetMarkers) {
      props.setFilterMarkerType('assetMarker')
      props.setShowSidebar(showAssetMarkers);
      props.setToggleLineLayer(true);
    } else {
      props.setShowSidebar(false);
      props.setFilteredDetectionTypes([]);
      props.setFilterMarkerType('detectionMarker');
      props.setToggleLineLayer(false);
    }
  }, [showAssetMarkers]);
  return (
    <div className='position-relative'>
      <Button
        style={{
          float: 'right',
          minWidth: 43,
          height: 43,
          zIndex: 999,
          marginRight: '0.78rem',
          position: 'relative',
          top: 240,
          backgroundColor: 'white',
          borderRadius: 0,
        }}
        onClick={() => { props.onClickMenu('filter'); props.onClickDetection(null); }}
      >
        <img style={{ width: 25, height: 25 }} src={filterIcon} alt="filtericon" />
      </Button>
      {props.openFilterMenu && (
        <Box
          item
          xs={6}
          md={4}
          className="sidebar ml-auto"
          style={{
            zIndex: 999,
            position: 'absolute',
            top: 244,
            right: 65,
            width: '15rem',
          }}
          onMouseOver={() => props.setOnHover(true)}
          onMouseLeave={() => props.setOnHover(false)}
        >
          <div
            className="p-8"
            style={{
              // display: 'flex',
              justifyContent: 'left',
              flexWrap: 'wrap',
              backgroundColor: 'white',
              outline: '1px solid var(--primary-color)',
              borderRadius: 3,
            }}
          >
            <Grid
              container
              spacing={1}
              className="d-flex flex-column py-3 px-4"
              style={{ minWidth: '100%' }}
            >
              <Grid xs={12} className='d-flex justify-content-between align-items-center'>
                <p className='text-uppercase' style={{ fontSize: '0.9rem', color: '#0061AA', fontWeight: 'bold', }}>Filter View</p>
                <IconButton color="secondary" className='p-0' onClick={() => props.setOpenFilterMenu(false)}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              style={{ maxHeight: isMediumMedia ? 615 : 420, overflow: 'auto', padding: 0 }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              className='hide-scroll'

            // subheader={(
            //   <ListSubheader component="div" id="nested-list-subheader">
            //     Nested List Items
            //   </ListSubheader>
            // )}
            >
              {filterList.map(fl => (
                <>
                  <ListItemButton
                    onClick={() => handleClick(fl.setOpen, fl.name)}
                    style={{ color: activeItem === fl.name ? '#51DBA5' : '#384768' }}
                  >
                    {fl.items.length ? (fl.open ? <ExpandMore /> : <ChevronLeft />) : <></>}
                    {fl.name == 'Slope & Bridge' && (<Checkbox checked={showAssetMarkers} />)}
                    <ListItemText
                      primary={fl.name}
                      style={{
                        color: activeItem === fl.name ? '#51DBA5' : '#384768',
                      }}
                    />
                  </ListItemButton>
                  <Divider />
                  <Collapse in={fl.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ marginLeft: '10px' }}>
                      {!fl.subCategory
                        ? fl.items.map(item => <ListItemButton onClick={() => { props.setFilteredDetectionTypes(pv => (pv.includes(item.id) ? pv.filter(id => id != item.id) : _.uniq([...pv, item.id]))); }} sx={{ pl: 4 }}><Checkbox /><ListItemText primary={typeof item == 'string' ? item : item.name} /> </ListItemButton>)
                        : fl.subCategory.map(category => (
                          <>
                            <ListItemButton onClick={() => openFilterSubCategory(category)}>
                              {openSubCategory && openSubCategory == category ? <ExpandMore /> : <ChevronLeft />}
                              <ListItemText primary={category} />
                            </ListItemButton>
                            <Divider />
                            <Collapse in={openSubCategory && openSubCategory == category} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                {fl.items.map(item => <ListItemButton className='py-0' onClick={() => { props.setFilteredDetectionTypes(pv => (pv.includes(item.id) ? pv.filter(id => id != item.id) : _.uniq([...pv, item.id]))); }} sx={{ pl: 4 }}><Checkbox checked={props.filteredDetectionTypes.includes(item.id)} /><ListItemText primary={typeof item == 'string' ? item : item.name} /> </ListItemButton>)}
                              </List>
                            </Collapse>
                          </>
                        ))}
                    </List>
                  </Collapse>
                </>
              ))}
            </List>
          </div>
        </Box>
      )}
    </div>
  )
}
