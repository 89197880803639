import React from 'react';
import {
  FormControl, InputLabel, Select, MenuItem, OutlinedInput,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menuItem: {
    color: 'white',
    backgroundColor: '#141e48',
    '&:focus': {
      color: '#gray',
      backgroundColor: 'white',
    },
    '&:hover': {
      color: '#gray',
      backgroundColor: '#141e48',
    },
  },
  icon: {
    fill: 'white',
  },
}));

const useOutlinedInputStyles = makeStyles(() => ({
  root: {
    '& $notchedOutline': {
      borderColor: 'gray',
    },
    '&:hover $notchedOutline': {
      borderColor: 'gray',
    },
    '&$focused $notchedOutline': {
      borderColor: 'gray',
    },
    color: 'blue',
    textTransform: 'capitalize',
  },
  input: {
    padding: '7px 10px',
  },
  focused: {},
  notchedOutline: {},
}));

export default ({
  title, selected, setSelected, itemList, containerProps = null,
}) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  return (
    <div className="d-flex align-items-center" style={{ ...containerProps }}>
      <InputLabel className="m-0" style={{ color: 'gray', fontFamily: 'CeraProRegular' }}>{title} : </InputLabel>
      <FormControl className="ml-1">
        <Select
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
          className="text-black"
          style={{ fontFamily: 'CeraProRegular', color: '#30A6D3', }}
          input={(
            <OutlinedInput
              // labelWidth={labelWidth}
              name="age"
              id="outlined-age-simple"
              classes={outlinedInputClasses}
            />
          )}
          inputProps={{
            classes: { icon: classes.icon },
          }}
          MenuProps={{
            MenuListProps: { disablePadding: true },
          }}
        >
          {itemList?.map((e, eIdx) => (
            <MenuItem value={e.value} dense className={classes.menuItem}>
              <span>{(e.title ?? e.label ?? `Item ${eIdx + 1}`).toUpperCase()}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
